import React from 'react';
import axios from 'axios';


class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {email: '', password: '', errorMessage: ''}
     
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.config = window.appConfig;

    console.log(this.config);
  }

  ValidateEmail(inputText){
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  };

  handleEmailChange(e){
    this.setState({email: e.target.value});
  }

  handlePasswordChange(e){
    this.setState({password: e.target.value});
  }
  
  handleButtonClick(e){

    // Clear the error message
    this.setState({errorMessage: ''});

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if(!this.state.email
      || !this.state.password){
      this.setState({errorMessage: 'Email or password is empty.'});
      return;
    }

    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!this.state.email.match(mailformat))
    {
      this.setState({errorMessage: 'Email is not in a valid format.'});
      return;
    }
    

    let url = this.config.baseUrl + 'user/sign-in';
    
    // Post the user name and password
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ email: this.state.email, password: this.state.password })
    // };

    // console.log(this.config.baseUrl);

    
    // fetch(url, requestOptions)
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data);
    //     this.props.setUserData(data);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     return err;
    //   });

    axios.post(url, 
      { email: this.state.email, password: this.state.password },
      {headers: { 'content-type': 'application/json' }},
    )
    .then((response) => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.setState({errorMessage: 'Email or password is wrong.'});
      }
      else {
        console.log(response.data);
        this.props.setUserData(response.data);
      }
    })
    .catch((error) => {
      this.setState({errorMessage: 'Email or password is wrong.'});
      console.log(error);
    });
  }

  render(){

    return (
      <div className="w-full h-screen flex felx-col justify-center content-center">
        <div className="leading-loose flex flex-col justify-center">
          
          <div className="w-96 m-4 p-10 bg-white rounded shadow-xl">
          <h1 className="m-4 text-center">Welcome to H4N</h1>
            <p className="text-gray-800 font-medium text-center text-lg font-bold">Login</p>
            <div className="mt-2">
              <label className="block text-sm text-gray-00" htmlFor="email">Email</label>
              <input className="w-full px-5 py-1 text-gray-700 bg-gray-200 rounded" id="email" name="email" type="text" 
                required="" placeholder="User Name" aria-label="email" value={this.state.email} onChange={this.handleEmailChange} />
            </div>
            <div className="mt-2">
              <label className="block text-sm text-gray-600" htmlFor="password">Password</label>
              <input className="w-full px-5  py-1 text-gray-700 bg-gray-200 rounded" id="password" name="password" type="password" 
              required="" placeholder="*******" aria-label="password" value={this.state.password} onChange={this.handlePasswordChange}/>
            </div>
            <div className="mt-4 items-center justify-between">
              <button type="submit" className="button-primary" onClick={this.handleButtonClick} >Login</button>
            </div>
            <p className="text-red-500">{this.state.errorMessage}</p>
          </div>
         
        </div>
        
      </div>
    );
  }
}
export default Login;
import React from 'react';
import axios from 'axios';
import {Checkbox, formatMs, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Button, IconButton} from '@material-ui/core';
import usersList from './usersList';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import validateForm from '../helpers/formValidator';
import { withSnackbar } from 'notistack';




class UserRegistration extends React.Component {
  constructor(props){
    super(props);
    this.state = {user: {email: '', password: '', isMillieUser:false, militaryStatus: 0}, formErrors: {}}
    this.saveSignUpDetails = this.saveSignUpDetails.bind(this);

    this.config = window.appConfig;

  }
  componentDidMount(){
  }

  saveSignUpDetails(){

    if(!this.validateUser()){

      this.props.enqueueSnackbar('Please fill all the required form fields', {variant: 'error'});
      return;
    }
    
    console.log(this.state.user);

    let url = this.config.baseUrl + 'user/sign-up';
    axios.post(url, this.state.user)
        .then(response => {
          console.log(response.data);
            // this.setState(state=> { 
            //   return {user: Object.assign(state.user)};
            // });
          this.props.enqueueSnackbar('User registration successful', {variant: 'success'});

        })      
        .catch(error => {
          console.log('There was an error!' + error)
          this.props.enqueueSnackbar(error.response.data, {variant: 'error'});
        });
  }

  validateUser(){
    let user = this.state.user;
    const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const validations = [

      {email: [{ruleName: 'required', message: 'email required'},
        {ruleName: 'regex', regex: emailRegex, message: 'Incorrect email format'}]},

      {password: [{ruleName: 'required', message: 'password required'},
      {ruleName: 'minLength', minLength: 8, message: 'Password must contain atleast 8 letters'}]},  
      
      {militaryStatus: [{ruleName: 'required', message: 'Select the military status'}]},

  
    ]

    let errors = validateForm(validations, user);

    // Set the errors to the state.
    this.setState({formErrors: errors});

    return Object.keys(errors).length === 0;

  }

  updateUser(delta){
    this.setState(state => {
      return {user: Object.assign(state.user, delta)};
    });
  }
  
  render(){
    const user = this.state.user;
    let errors = this.state.formErrors;

    return (
      
      <div className="w-1/2 mx-auto text-gray-500 flex flex-col justify-items-center">
        <div className="w-full text-center mt-5">
          <Typography variant="h5" gutterBottom>User Registration</Typography>
        </div>
        <table className="form-table w-full mx-auto">
          <tbody>
            <tr><th className="">Email<span className="mandatory-indicator">*</span></th>
              <td>
                <TextField type="text" value={user.email} placeholder="eg. abc.def@xyz.com" fullWidth 
                onChange={(e) => this.updateUser({email: e.target.value})} 
                error={!!errors.email} helperText={errors.email?.message}/>
              </td>
            </tr>
            <tr><th >Password<span className="mandatory-indicator">*</span></th>
              <td>
                <TextField type="password" value={user.password}  placeholder="Password" fullWidth 
                onChange={(e) => this.updateUser({password: e.target.value})} 
                error={!!errors.password} helperText={errors.password?.message}/>
              </td>
            </tr>
            <tr><th >Millie User</th>
              <td><Checkbox color="primary" checked={user.isMillieUser} 
                onClick={(e) => this.updateUser({isMillieUser: e.target.checked})}
                />
              </td> 
            </tr>
            <tr><th>Military Status<span className="mandatory-indicator">*</span></th>
              <td>
                <Select native value={user.militaryStatus} fullWidth
                  onChange={(e)=> this.updateUser({militaryStatus: +e.target.value})}
                  inputProps={{name: 'military-status',id: 'military-status'}}
                  error={!!errors.militaryStatus}>

                  <option disabled value="0">-- Select military status --</option>
                  <option value="1">Active Duty</option>
                  <option value="2">Veteran</option>
                  <option value="3">Military Spouse</option>
                </Select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex felx-row justify-center w-1/2 ">
          <Button color="primary" variant="contained" onClick={this.saveSignUpDetails} className="mr-5">Register</Button><span className="m-5"></span>
          <Button color="secondary"  variant="contained"><Link to="/users">Cancel</Link></Button>
        </div>
      </div>
    );
  }
}
export default withSnackbar(UserRegistration);

import axios from 'axios';
import React from 'react';
import ScoutDialog from '../components/scoutDialog';
import { withSnackbar } from 'notistack';
import { Button, Link} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import RateReviewIcon from '@material-ui/icons/RateReview';

/* global google */

class PropertyView extends React.Component {

  constructor(props){
    super(props);

    this.state = {property: {}, militaryBases: [], propertyOptions: [], millieUserEmails: [], 
    dialogOpen: false, selectedEmail: ''};

    this.militaryBaseHash = {};
    
    this.config = window.appConfig;
    this.map = {};

    this.svgMarker = {
      path:
        "M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z",
      fillColor: "blue",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    };

    this.directionsService = null;
    this.directionsDisplay = null;
    this.closeDialog = this.closeDialog.bind(this);
    this.emailSelected = this.emailSelected.bind(this);

  }

  initMap(){
    this.map = new google.maps.Map(document.getElementById('directions-map'), {
      center: {lat: 37.7240554, lng: -96.0394503},
      zoom: 4,
      fullscreenControl: false,
      streetViewControl : false,
      mapTypeControlOptions: {
          mapTypeIds: []
      }
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
    this.directionsDisplay.setMap(this.map);
  }

  showMilitaryBases(property, bases){


    let bounds = new google.maps.LatLngBounds();
    bounds.extend({lat: property.latitude, lng: property.longitude});

    bases.forEach(b => {
      bounds.extend({lat: b.latitude, lng: b.longitude});
      let icon = {
        url: this.config.clientBasePath + '/images/military_base_icon.png', // url
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // origin: new google.maps.Point({lat: b.latitude, lng: b.longitude}), // origin
        // anchor: new google.maps.Point({lat: b.latitude, lng: b.longitude}) // anchor
      };

      new google.maps.Marker({position: {
        lat: b.latitude,
        lng: b.longitude
      },
      icon: icon,
      map: this.map,
      zIndex: -1,
      title: b.militaryBaseName
      });
    });

    // add the icon for the property location
    new google.maps.Marker({
      position: {
        lat: property.latitude,
        lng: property.longitude
      },
      map: this.map,
      zIndex: -1,
      title: property.title
    });

    // set the map bounds
    this.map.fitBounds(bounds);

    // set the directions to the first military base from the property
    if(bases && bases.length > 0){
      this.setMapDirections(bases[0]);
    }
    
  }

  setMapDirections(base){
    let property = this.state.property;
    
    // set the direction from the given military base to the property
    this.directionsService.route({
      origin: new google.maps.LatLng(property.latitude, property.longitude),
      destination: new google.maps.LatLng(base.latitude, base.longitude),
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
          this.directionsDisplay.setDirections(response);

          // Set the new bounds so that the directions are displayed properly.
          let bounds = new google.maps.LatLngBounds();
          bounds.extend({lat: property.latitude, lng: property.longitude});
          bounds.extend({lat: base.latitude, lng: base.longitude});

          this.map.fitBounds(bounds);

      } else {
          console.log('Directions request failed due to ' + status);
      }
    });
  }

  closeDialog(){
    this.setState({dialogOpen: false});
  }

  emailSelected(selectedEmail){

    // Send the email to the scout
    let url = this.config.baseUrl + 'property/scout';
    let reviewLink = this.config.appBaseUrl + 'property-review/' + this.state.property.id;
    let input = {propertyId: this.state.property.id, email:selectedEmail, address: this.state.property.address,
      reviewLink: reviewLink
     };

    axios.post(url, 
      input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar('Mail sent to Scout.', {variant: 'success'});

      }
    })
    .catch(error => {
      this.props.enqueueSnackbar('error', {variant: 'error'});
    })

    this.setState({dialogOpen: false});
  }

  componentDidMount(){

    this.initMap();

    const { match: { params } } = this.props;

    // Load the military bases
    let url = this.config.baseUrl + 'military-base/all';

    let request1 = axios.get(url, 
      {headers: { 'content-type': 'application/json' }},
    );

    let propertyId = params.propertyId || -1;

    url = this.config.baseUrl + 'property/detail/' + propertyId;
    let request2 = axios.get(url, 
      {headers: { 'content-type': 'application/json' }},
    );

    url = this.config.baseUrl + 'property-options/index';
    let request3 = axios.get(url, 
      {headers: { 'content-type': 'application/json' }},
    );

    axios.all([request1, request2, request3])
    .then(axios.spread((...responses) => {
      const res1 = responses[0];
      const res2 = responses[1];
      const res3 = responses[2];
      let militaryBases = [];
      let property = {};
      let propertyOptions = [];

      if(res1.status === false){
        console.log('Got error from the server' + res1.statusText);
      }
      else {
        console.log(res1.data);
        
        // update the military bases.
        militaryBases = res1.data.militaryBases;
        
      }

      if(res2.status === false){
        console.log('Got error from the server' + res2.statusText);
      }
      else {
        console.log(res2.data);
        
        property = res2.data;
        
      }

      if(res3.status === false){
        console.log('Got error from the server' + res3.statusText);
      }
      else {
        console.log(res3.data);
        
        propertyOptions = res3.data;
        
      }

      this.setState({militaryBases: militaryBases, property: property, propertyOptions: propertyOptions});

      this.showMilitaryBases(property, property.militaryBases);

    }));

    this.loadMillieUsers();

  }

  loadMillieUsers(){
    const filters = {email: '', userType: -1, isMillieUser: 1, pageSize: 10000, pageNo: 1};
    let url = this.config.baseUrl + 'user/filter';
      axios.post(url, 
        filters,
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
          this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
        }
        else {
          console.log(response.data);
          let emails = response.data.users.map(u => u.email);
          this.setState({millieUserEmails:emails});
        }
      })
      .catch(error => {
        console.log(error);
        this.props.enqueueSnackbar(error, {variant: 'error'});
      });
  }

  getSelectedOptions(){
    const options = this.state.propertyOptions;
    const propertyOptions = this.state.property.propertyOptions;
    if(!options || options.length === 0){
      return '';
    }

    const selectedOptions = options.filter(po => propertyOptions.find(so => so === po.id));
    let str = selectedOptions.map(so => so.name).join(', ');

    console.log(selectedOptions);
    console.log('selected options');
    console.log('options: ' + str);
    return str;
  }
 

  render(){
    let property = this.state.property;
    

    let selectedBases = this.state.property.militaryBases;
    

    return (
      <div className="w-full">
        <div className="w-full flex flex-row justify-between my-6">
          <div><h2>Property</h2></div>
          <div disabled={!property.id} className="flex flex-row justify-end space-x-2">

           <Button disabled={true} component={Link} variant="contained" color="primary" startIcon={<RateReviewIcon/>}
              href={this.config.clientBasePath + '/create-millie-review/' + property.id} title="rate a review">
                Add a Review
            </Button>
             
            <Button color="primary" variant="contained" disabled={ !this.state.millieUserEmails.length} 
             onClick={() => this.setState({dialogOpen: true})} startIcon={<MailOutlineIcon/>} >Send to scout
            </Button> 
            <Button disabled={!property.id} component={Link} variant="contained" color="secondary" startIcon={<EditIcon/>}
              href={this.config.clientBasePath + '/property-edit/' + property.id} title="edit property">
                Edit
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap -mx-2 mb-8">
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-grey-dark flex items-center justify-center">
              <table className="table-fixed w-full text-left text-gray-500 property-view-table">
                <tbody>
                  <tr><th className="w-1/4">Title</th><td className="w-3/4">{property.title}</td></tr>
                  {/* <tr><th>Url</th><td>{property.url}</td></tr> */}
                  <tr><th>Type</th><td>{property.propertyType}</td></tr>
                  <tr><th>Options</th><td>{this.getSelectedOptions()}</td></tr>
                  <tr><th>Price Category</th><td>{property.priceCategory}</td></tr>
                  <tr><th>Property Ownership</th><td>{property.propertyOwnershipText}</td></tr>
                  <tr><th>Category</th><td>{property.category}</td></tr>
                  <tr><th>Short Term Rent</th><td>{property.isShortTermRent ? property.rentPerDay : 'No'}</td></tr>
                  <tr><th>Long Term Rent</th><td>{property.isLongTermRent ? property.rentPerMonth : 'No'}</td></tr>
                  <tr><th>For Sale</th><td>{property.isForSale ? 'Yes' : 'No'}</td></tr>
                  <tr><th>Full Cost</th><td>{property.isForSale ? property.fullCost : ''}</td></tr>
                  <tr><th>Monthly Payment</th><td>{property.isForSale ? property.monthlyPayment: ''}</td></tr>
                  <tr><th>Description</th><td>{property.description}</td></tr>                
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-grey-dark">
                <div className="w-full mb-5">
                  <div className="w-full flex flex-row justify-items-start mb-3">
                    <strong className="mr-5">Address</strong><span>{property.address}</span>
                  </div>
                  <div className="w-full p-4" style={{height: '400px'}} id="directions-map">

                  </div>
                </div>

                <div className="w-full">
                  <table className="table-fixed w-full text-left text-gray-500 property-view-table">
                    <thead>
                      <tr>
                        <th>Military Base</th>
                        <th>Miles</th>
                        <th>Minutes</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        selectedBases && selectedBases.map(b => (<tr key={b.militaryBaseId}>
                          <td>{b.militaryBaseName}</td>
                          <td>{b.milesToMilitaryBase}</td>
                          <td>{b.minutesToMilitaryBase}</td>
                          <td><button className="text-2xl" type="button" onClick={()=>this.setMapDirections(b)}><i className="fas fa-map-marker-alt text-blue-400"></i></button></td>
                        </tr>))
                      }
                    </tbody>
                  </table>
                </div>

                <div className="w-full mt-3">
                  <table className="table-fixed w-full text-left text-gray-500 property-view-table">
                    <tbody>
                      <tr><th className="pl-3">Owner First Name</th><td>{property.ownerFirstName}</td></tr>
                      <tr><th className="pl-3">Owner Last Name</th><td>{property.ownerLastName}</td></tr>
                      <tr><th className="pl-3">Owner Phone</th><td>{property.ownerPhone}</td></tr>
                      <tr><th className="pl-3">Owner Email</th><td>{property.ownerEmail}</td></tr>
                      <tr><th className="pl-3">Owner Address</th><td>{property.ownerAddress}</td></tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-2 mb-8">
        <div className="w-full lg:w-1/2 px-3">
          <p><strong>Main Image</strong></p>
          <img className="w-full" src={property.mainImageUrl} alt="property main image"></img>
        </div>
        <div className="w-full lg:w-1/2 px-3">
          <p><strong>Gallery</strong></p>
          <div className="w-full flex flex-wrap flex-row">
            {
              property.gallery && property.gallery.map(url => <div key={url} className="md:w-full lg:w-1/3 p-2"><img className="w-full" src={url} alt="gallery image"></img></div>)
            }
          </div>
        </div>
        </div>

        <ScoutDialog emails={this.state.millieUserEmails} 
          isOpen={this.state.dialogOpen}
          closeDialog={this.closeDialog}
          emailSelected={this.emailSelected}
        ></ScoutDialog>
      </div>
    );
  }
}

export default withSnackbar(PropertyView);
import React from 'react';
import JSONPretty from 'react-json-pretty';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import Papa from 'papaparse';
import { Button, Typography} from '@material-ui/core';
import moment from 'moment';

class PerDiemRatesManager extends React.Component {

  constructor(props){
    super(props);
    this.state = {loadSummary: {}};
    this.config = window.appConfig;

    this.perDiemRatesRef = React.createRef();


  }

  componentDidMount(){
    this.getLoadSummary();
  }

  getLoadSummary(){
    let url = this.config.baseUrl + 'per-diem-rates/load-summary';
    
    axios.get(url,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        console.log(response.data);
        this.setState({loadSummary: response.data});
      }     
    })
  }

  onFileChange(e){
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];

    
    this.parseData(file, 1); // 1 -- with dependents

    
    this.setState({file}); /// if you want to upload latter
  }

  parseData(file){
    Papa.parse(file, {
      header: false,
      dynamicTyping: true, // so that we get the numbers as numbers and not strings.
      skipEmptyLines: true,
      complete: (results) => {
        console.log(results);

        // get the pay grades as an array of arrays.
        // RECHECK: The first row looks like a header but it actually not.
        results.data.shift();
        let perDiemRates = results.data.map(r => this.getRecord(r));

        console.log(perDiemRates);

        // Save the pay grades.
        this.saveRates(perDiemRates, results.errors);
      }
    });
  }
  getRecord(row){
    let begin = +row[3].replace('/', ''); // take out the forward slashes and convert to integer.
    let end = +row[4].replace('/', ''); // take out the forward slashes and convert to integer.
    let perDiem = +row[8] * this.config.toCentsFactor;
    return {state: row[0], city: row[1], seasonBegin: begin, seasonEnd: end, perDiem: perDiem};
  }

  saveRates(perDiemRates, errors){
    let url = this.config.baseUrl + 'per-diem-rates/save';
    let input = {
      perDiemRates: JSON.stringify(perDiemRates),
      errors: JSON.stringify(errors)
    }

    axios.post(url, 
      input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar(`Uploaded the per Diem rates`, {variant:'success'});
        this.setState()        
      }
    })
    .catch(error => {
      this.props.enqueueSnackbar(error, {variant: 'error'});
    });
  }

  render(){

    let loadSummary = this.state.loadSummary;
    let loadTime = loadSummary.insertDateTimeUtc ? moment(loadSummary.insertDateTimeUtc).format('MM/DD/YYYY, hh:mm:ss') : '-';

    return (
      <div className="flex flex-wrap py-4 mt-3">
        <div className="w-full lg:w-1/2 px-3">
          <Typography variant="h6" gutterBottom>Upload Per Diem Rates</Typography>

          <div className="w-full flex flex-row justify-between">
            <input id="per-diem-rates-file" type="file" ref={this.perDiemRatesRef} accept=".csv,.txt"
              style={{display: 'none'}} onChange={this.onFileChange.bind(this)}/>
            

            <Button color="primary" variant="contained" onClick={() => this.perDiemRatesRef.current.click()} >Upload</Button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-3">
          <div className="style=border:2px; border-fuchsia-600">
          <Typography variant="h6" gutterBottom>Last upload summary</Typography></div>
          <table className="table-fixed w-full  text-left text-gray-500">
    
            <tbody>
              <tr><td>Rates updated</td><td>{loadSummary.ratesUpdated}</td></tr>
              <tr><td>Total</td><td>{loadSummary.ratesTotal}</td></tr>
              <tr><td>Last upload Time</td><td>{loadTime}</td></tr>
              <tr><td>Errors</td>
                <td>
                  <JSONPretty id="json-pretty" data={loadSummary.errors}></JSONPretty>
                </td>
              </tr>
            </tbody>  
          </table>
        </div>
      </div>
    );
  }
}

export default withSnackbar(PerDiemRatesManager);
import React from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import FilterDropdown from '../formControls/filterDropdown';
import FilterTextField from '../formControls/filterTextField';



class MillieReviews extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {properties: [], propertiesCount: 0, militaryBases: [], filters:{
      title: '',
      address: '',
      militaryBaseId: -1,
      isVerified: -1, // so that we get the properties loaded as all the properties are verified in dev database.
      reviewIsVerified: -1,
      propertyOwnership: 2,
      mustHaveReview: true,
      pageSize: 15, // If this is to be configurable, add it to the config.js file --TODO:
      pageNo: 1
    }};

    this.booleanOptions = [{name:'No', value:0}, {name:'Yes', value:1}]

    this.mounted = false;

    this.config = window.appConfig;

    this.columns = [{title:'#', field:'id', filtering: false, render: rowData => <a href={this.config.clientBasePath + '/view-millie-review/' + rowData.id}>{rowData.id}</a>},
      {title:'Main Image', field:'mainImageUrl', filtering: false, render: rowData => <img src={rowData.mainImageUrl} alt={'property'} style={{width: 50, borderRadius: '10%'}}/>},

      {title:'Title', field:'title', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.title} />,
      render: rowData => <a className="text-green-400 hover:text-green-500 delay-1" href={this.config.clientBasePath + '/view-millie-review/' + rowData.id}>{rowData.title}</a>},

      {title:'Address', field:'address', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.address} />},

      {title:'Military Bases', field:'militaryBases', 
        filterComponent:(props) => <FilterDropdown {...props} initialValue={this.state.filters.militaryBaseId}
        options={this.state.militaryBases} />},

      {title:'Is Verified', field:'isVerified', 
        filterComponent: (props) => <FilterDropdown {...props} 
        initialValue={this.state.filters.isVerified} options={[{id: 2, name: 'No'}, {id: 1, name: 'Yes'}]} />, 
        render: rowData => rowData.isVerified? 'Yes': 'No'},

      {title:'Review Is Verified', field:'reviewIsVerified',
        filterComponent: (props) => <FilterDropdown {...props} 
        initialValue={this.state.filters.isVerified} options={[{id: 2, name: 'No'}, {id: 1, name: 'Yes'}]} />,
        render: rowData => rowData.reviewIsVerified? 'Yes': 'No' },

      {title:'Rating', field:'rating', filtering: false},

    ];

    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount(){
    // Load the properties.

    this.mounted = true;

    // Load the military bases
    let url = this.config.baseUrl + 'military-base/index';

    axios.get(url, 
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
      }
      else {
        console.log(response.data);
        
        // update the properties.
        // this.setState({properties: response.data.properties, propertiesCount: response.data.totalRecords})

        let militaryBases = response.data.militaryBases;

        this.setState({militaryBases: militaryBases});
      }
    });

    // let url = this.config.baseUrl + 'property/list';

    // axios.post(url, 
    //   this.state.filters,
    //   {headers: { 'content-type': 'application/json' }},
    // )
    // .then((response) => {
    //   if(response.status === false){
    //     console.log('Got error from the server' + response.statusText);
    //   }
    //   else {
    //     console.log(response.data);
        
    //     // update the properties.
    //     this.setState({properties: response.data.properties, propertiesCount: response.data.totalRecords})
    //   }
    // })
    // .catch(function(error){
    //   console.log(error);
    // });
  }

  loadProperties(query){

    // if(this.mounted){
    //   this.setState(function(state, props){
    //     return {
    //       filters: Object.assign(state.filters, {pageNo: query.page ? query.page : 1})
    //     };
    //   });
    // }

    let localFilters = {};

    query.filters.forEach(filter => {
      if(filter.column.field === 'militaryBases'){
        localFilters['militaryBaseId'] = filter.value;
      }
      else if(filter.column.field === 'propertyOwnershipText'){
        localFilters['propertyOwnership'] = filter.value === null? -1: filter.value;
      }
      else if(filter.column.field === 'isVerified' || filter.column.field === 'reviewIsVerified'){
        localFilters[filter.column.field] = filter.value === 2? 0: filter.value;
      }
      else {
        localFilters[filter.column.field] = filter.value;
      }
    });

    let filters = Object.assign(localFilters, {pageNo: query.page + 1,
      pageSize: query.pageSize,

    });

    filters = Object.assign(this.state.filters, filters);

    console.log(query);

    return new Promise((resolve, reject) => {

      let url = this.config.baseUrl + 'property/list';

      


      axios.post(url, 
        filters,
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
        }
        else {
          console.log(response.data);
          
          // update the properties.
          // this.setState({properties: response.data.properties, propertiesCount: response.data.totalRecords})

          console.log('data for page: ' + response.data.pageNo);
          return resolve({
            data: response.data.properties,
            page: response.data.pageNo - 1,
            totalCount: response.data.totalRecords,
          });
        }
      });

      
    });
  }

  pageChanged(pageNo){
    console.log('page Changed to: ' + pageNo);

    if(this.mounted){
      this.setState(function(state, props){
        return {
          filters: Object.assign(state.filters, {pageNo: pageNo + 1})
        };
      });
    }
  }

  render(){

    let pageSize = this.state.filters.pageSize;

    return (
    <div className="w-full">

      <MaterialTable
        title="Millie Reviews"
        
        options={{
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [pageSize],
          toolbar: true,
          paging: true,
          filtering: true,
          search: false,
          sorting: false,
          paginationType: 'stepped'
        }}
        columns={this.columns}
        data={query => this.loadProperties(query)}
      />
    </div>
    );
  }

}

export default MillieReviews;
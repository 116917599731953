import axios from 'axios';
import React from 'react';
import { withSnackbar } from 'notistack';
import StarIcon from '@material-ui/icons/Star';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';


class PropertyReview extends React.Component {

  constructor(props){
    super(props);
    
    this.state = {maxRating: 5, selectedRating: 0, reviewText: '', propertyId: 0};
    this.config = window.appConfig;
    this.savePropertyReview = this.savePropertyReview.bind(this);

    this.setReviewText = this.setReviewText.bind(this);
    
  }


  ratingSelected(rating){
    this.setState({selectedRating: rating});
  }

  setReviewText(e){
    this.setState({reviewText: e.target.value});
  }

  componentDidMount(){
    const { match: { params } } = this.props;
    this.setState({propertyId: params.propertyId || 0});
  }

  savePropertyReview(){
    console.log("event fired");
    console.log(this.state.propertyId);
    let url = this.config.baseUrl + 'property/save-review';
    let input = {propertyId: this.state.propertyId, selectedRating:this.state.selectedRating, 
                  reviewText: this.state.reviewText};
    axios.post(url, input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar('Review updated successfully.', {variant: 'success'});

      }     
    })
  }

  

  render(){

    let ratings = [1, 2, 3, 4, 5];
    let selectedRating = this.state.selectedRating;

    return (
      <div>
        <h2 className="mt-4 p-3">Property Review</h2>
        <div className="flex flex-row justify-center my-4">
          
          <div className="w-full lg:w-1/2 p-3" >
            <div className="">
              <label>Review</label>
              <textarea className="w-full border border-gray-200 focus:outline-none focus:ring focus:ring-cyan-600 h-32" onChange={this.setReviewText}></textarea>
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-3" >
            <label>Gallery</label>
          </div>
          
        </div>

        <div className="w-full p-3" >
          <label>Rating</label>
          <div className="w-auto h-auto flex flex-row justify-start">
            {
              ratings.map(r => r <= selectedRating ? 
              <StarIcon key={r} fontSize="large" color="primary" onClick={() => this.ratingSelected(r)} /> : 
              <StarIcon key={r} fontSize="large" color="secondary" onClick={() => this.ratingSelected(r)} />)
            }
          </div>
        </div>

        <div>    
          <div className="w-full p-3 flex flex-row justify-start space-x-3" >
              <Button onClick={this.savePropertyReview} variant="contained" color="primary" size="large" 
              startIcon={<SaveIcon />} disabled={!this.state.selectedRating || !this.state.reviewText}>Save</Button>
              <Button  href={this.config.clientBasePath + '/property-view/' + this.state.propertyId} variant="contained" color="secondary" size="large">Cancel</Button>
          </div>
        </div>
        
      </div>
    );
  }
}

export default withSnackbar(PropertyReview);




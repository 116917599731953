
const Footer = () => (
  <footer className="full-width">
      <div className="mx-auto w-96 p-3 text-center text-sm">
          Copyright &copy; 2021, H4N
      </div>
  </footer>
)

export default Footer;

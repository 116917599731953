

import axios from 'axios';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { Button, IconButton} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { withSnackbar } from 'notistack';
import validateForm from '../helpers/formValidator';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import InputAdornment from "@material-ui/core/InputAdornment";
import ImageCropper from '../components/imageCropper';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ClearIcon from '@material-ui/icons/Clear';


/* global google */

class PropertyEdit extends React.Component {

  constructor(props){
    super(props);

    this.state = {property: {}, militaryBases: [], propertyOptions: [],
      propertyTypes: [], categories: [], priceCategories: [], propertyOwnerships: [], formErrors: {},
      imageCropData: {dialogOpen: false, selectedFileResult: null, propertyId: -1, fileName: '', isMainImage: false}};

    this.militaryBaseHash = {};

    this.config = window.appConfig;
    this.map = {};

    this.directionsService = null;
    this.directionsDisplay = null;
    this.savePropertyDetails = this.savePropertyDetails.bind(this);

    this.mapMarkers = [];

    this.mainImageFileRef = React.createRef();
    this.galleryImageFileRef = React.createRef();
    this.closeDialog = this.closeDialog.bind(this);
    this.setImageUploadResult = this.setImageUploadResult.bind(this);

  }

  initMap(){
    this.map = new google.maps.Map(document.getElementById('directions-map'), {
      center: {lat: 37.7240554, lng: -96.0394503},
      zoom: 4,
      fullscreenControl: false,
      streetViewControl : false,
      mapTypeControlOptions: {
          mapTypeIds: []
      }
    });

    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
    this.directionsDisplay.setMap(this.map);
  }

  closeDialog(){
    // Close the dialog by setting the dialogOpen to false.
    this.setState({imageCropData: {dialogOpen: false, selectedFileResult: null}});
  }

  showMilitaryBases(fromInit){
    let bases = this.state.property.militaryBases;
    let property = this.state.property;
    let marker = null;

    // clear all the markers.
    this.mapMarkers.forEach(function(m) {
      m.setMap(null);
    });
    this.mapMarkers = [];

    let bounds = new google.maps.LatLngBounds();
    bounds.extend({lat: property.latitude, lng: property.longitude});

    bases.forEach(b => {
      bounds.extend({lat: b.latitude, lng: b.longitude});
      let icon = {
        url: '/images/military_base_icon.png', // url
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // origin: new google.maps.Point({lat: b.latitude, lng: b.longitude}), // origin
        // anchor: new google.maps.Point({lat: b.latitude, lng: b.longitude}) // anchor
      };

      marker = new google.maps.Marker({position: {
        lat: b.latitude,
        lng: b.longitude
      },
      icon: icon,
      map: this.map,
      zIndex: -1,
      title: b.militaryBaseName
      });

      this.mapMarkers.push(marker);
    });

    // add the icon for the property location
    marker = new google.maps.Marker({
      position: {
        lat: property.latitude,
        lng: property.longitude
      },
      map: this.map,
      zIndex: -1,
      title: property.title
    });

    this.mapMarkers.push(marker);

    // set the map bounds
    this.map.fitBounds(bounds);

    // set the directions to the first military base from the property
    if(bases && bases.length > 0 && fromInit){
      this.computeMapDirections(bases[0], true);
    }

  }

  fetchLatLngForProperty(force){
    let property = this.state.property;
    if(property.latitude && property.longitude && !force){
      return; // No need to fetch the geocode of the property.
    }

    let address = property.address;
    if(!address || address.length < 10){
      return; // Address is not available yet.
    }

    let url =  this.config.baseUrl + 'property/geocode?address=' + address;
    axios.get(url)
    .then(response => {
      if(response.data?.results?.length > 0){
        let result = response.data.results[0];
        let location = {latitude: result.geometry.location.lat, longitude: result.geometry.location.lng};

        // Update the property location.
        this.setState((state) => {
          return {property: Object.assign(this.state.property, location)}
        });
      }
    })
    .catch(err => {
      this.props.enqueueSnackbar('Error fetching the gecode of property address', {variant: 'error'});
      console.log(err);
    });
  }

  computeMapDirections(base, fromInit){
    let property = this.state.property;

    // set the direction from the given military base to the property
    this.directionsService.route({
      origin: new google.maps.LatLng(property.latitude, property.longitude),
      destination: new google.maps.LatLng(base.latitude, base.longitude),
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
          this.directionsDisplay.setDirections(response);

          console.log('response from the directions');
          console.log(response);

          if(!fromInit){
            let meters = 0;
            let seconds = 0;
            let route = response.routes.length > 0 ? response.routes[0] : null; // take first route.
            route.legs.forEach(leg => {
              meters += leg.distance.value;
              seconds += leg.duration.value;
            });

            let miles = Math.round(meters * this.config.metersToMiles);
            let minutes = Math.round(seconds * this.config.secondsToMinutes);

            // set the values to the military base.
            this.updateMilitaryBase(base, {milesToMilitaryBase: miles, minutesToMilitaryBase: minutes})
          }

          // Set the new bounds so that the directions are displayed properly.
          let bounds = new google.maps.LatLngBounds();
          bounds.extend({lat: property.latitude, lng: property.longitude});
          bounds.extend({lat: base.latitude, lng: base.longitude});

          this.map.fitBounds(bounds);

      } else {
          console.log('Directions request failed due to ' + status);
      }
    });
  }

  updatePropertyType(propertyTypeId){
    // this.updateProperty({propertyTypeId: propertyTypeId});

    // Update the options as well.
    const propType = this.state.propertyTypes.find(pt => pt.id === propertyTypeId)
    const options = propType.options;

    // Remove from the selected options any option that does not belong to the prop type options.
    const validOptions = this.state.property.propertyOptions.filter(po => options.indexOf(po) > -1);

    this.setState(state => {
      return {propertyOptions: options, property: Object.assign(state.property, {
        propertyTypeId: propertyTypeId, propertyOptions: validOptions
      })};
    });
  }
  
  removeBase(b){
    this.setState(state => {
      let bases = state.property.militaryBases;
      const baseIndex = bases.indexOf(b);
      if(baseIndex > -1){
        bases.splice(baseIndex, 1);
      }
      return {property: Object.assign(state.property, {militaryBases: bases})};
    });
  }

  componentDidMount(){

    this.initMap();

    const { match: { params } } = this.props;

    // Load the military bases
    let url = this.config.baseUrl + 'property/master-data';

    let request1 = axios.get(url,
      {headers: { 'content-type': 'application/json' }},
    );

    let propertyId = params.propertyId || -1;

    url = this.config.baseUrl + 'property/detail/' + propertyId;
    let request2 = axios.get(url,
      {headers: { 'content-type': 'application/json' }},
    );


    axios.all([request1, request2])
    .then(axios.spread((...responses) => {
      const res1 = responses[0];
      const res2 = responses[1];
      let militaryBases = [];
      let property = {};
      let propertyOptions = [];
      let propertyTypes = [];
      let categories = [];
      let priceCategories = [];
      let propertyOwnerships = [];

      if(res1.status === false){
        console.log('Got error from the server' + res1.statusText);
      }
      else {
        console.log(res1.data);

        // update the military bases.
        militaryBases = res1.data.militaryBases;
        propertyTypes = res1.data.propertyTypes;
        propertyOptions = res1.data.propertyTypes[0]?.options;
        categories = res1.data.categories;
        priceCategories = res1.data.priceCategories;
        propertyOwnerships = res1.data.propertyOwnerships;
      }

      if(res2.status === false){
        console.log('Got error from the server' + res2.statusText);
      }
      else {
        console.log(res2.data);

        property = res2.data;

      }


      this.setState({militaryBases: militaryBases, property: property, propertyOptions: propertyOptions,
        propertyTypes: propertyTypes, categories: categories, priceCategories: priceCategories, propertyOwnerships: propertyOwnerships });

      this.showMilitaryBases(true);

    }));

  }

  updateProperty(delta){
    this.setState((state, props) => {
      let prop = Object.assign(state.property, delta);

      return {property: prop};
    })
  }

  addMilitaryBase(){
    let bases = this.state.militaryBases;
    let selectedBases = this.state.property.militaryBases;

    let newBase = null;

    for(let i = 0; i < bases.length; i++){
      let b = bases[i];
      let matches = selectedBases.filter(sb => sb.militaryBaseId === b.id);

      if(!matches || matches.length === 0){
        newBase = b;
        break;
      }
    }

    if(newBase){
      let militaryBase = {
        militaryBaseId: newBase.id,
        militaryBaseName: newBase.name,
        latitude: newBase.latitude,
        longitude: newBase.longitude,
        minutesToMilitaryBase:  0,
        milesToMilitaryBase: 0
      };

      // this.updateMilitaryBase(base, changedBase);

      // Add the military base.
      selectedBases.push(militaryBase);
      this.setState((state) => {
        return {property: Object.assign(state.property, {militaryBases: selectedBases})};
      });

      // force update the component;
      // this.forceUpdate();
      this.showMilitaryBases();
      this.computeMapDirections(militaryBase, false);
    }

  }

  militaryBaseChanged(base, newMilitaryBaseId){
    // Check whether this military base is already in the list.
    let bases = this.state.property.militaryBases;

    let matches = bases.filter(b => b.militaryBaseId === newMilitaryBaseId);

    if(matches.length > 1){
      // Issue an error about the military base being selected, and ask the user to select some other military base
      this.props.enqueueSnackbar(`The base ${matches[0].name} is already added. Please choose a different base`, {variant: 'error'});
      return;
    }

    // update the military base.
    let newBase = this.state.militaryBases.find(b => b.id === newMilitaryBaseId);
    if(newBase){
      let changedBase = {
        militaryBaseId: newBase.id,
        militaryBaseName: newBase.name,
        latitude: newBase.latitude,
        longitude: newBase.longitude,
        minutesToMilitaryBase:  0,
        milesToMilitaryBase: 0
      };

      this.updateMilitaryBase(base, changedBase);

      changedBase = Object.assign(base, changedBase);

      this.computeMapDirections(changedBase, false);
    }
  }

  updateMilitaryBase(base, delta){
    this.setState((state, props)=>{
      let property = state.property;
      let bases = state.property.militaryBases;

      const index = bases.findIndex(b => b.militaryBaseId === base.militaryBaseId);

      if(index > -1)
      {
        let base = bases[index];

        base = Object.assign(base, delta);
        bases[index] = base;

        // save base bases to properties.
        property.militaryBases = bases;

        return {property: property}
      }

      return {};

    });
  }

  getSelectedOptions(selected){
    let options = this.state.propertyOptions;
    return selected.map(id => options.find(op => op.id === id)?.name || 'Unknown').join(', ');
  }

  savePropertyDetails(){

    if(!this.validateProperty()){

      this.props.enqueueSnackbar('Please fill all the required form fields', {variant: 'error'});
      return;
    }
    
    console.log(this.state.property);

    let url = this.config.baseUrl + 'property/save';
    axios.post(url, this.state.property)
        .then(response => {
          console.log(response.data);

          if(this.state.property.id < 1){
            // Save the property id.
            const propertyId = response.data;
            this.setState(state => {
              let bases = state.property.militaryBases;
              bases.forEach(b => b.militaryBaseId = propertyId);

              return {property: Object.assign(state.property, {id: propertyId, militaryBases: bases})};
            });
          }
          this.props.enqueueSnackbar('Property saved', {variant: 'success'});
        })      
        .catch(error => {
          console.log('There was an error!' + error)
          this.props.enqueueSnackbar('Error in saving property', {variant: 'error'});
        });
  }

  removeImage(e, imageUrl, isMainImage){
    e.preventDefault();
    e.stopPropagation();
    
    const url = this.config.baseUrl + 'property/remove-image/';
    const input = {propertyId: this.state.property.id, 
      imageUrl: imageUrl, isMainImage: isMainImage};

    axios.post(url, input)
      .then(response => {
        console.log(response.data);

        if(isMainImage){
          this.setState(state => {
            return {property: Object.assign(state.property, {mainImageUrl: null})};
          });
        }
        else if(response.data){
          // update the gallery images.
          this.setState(state => {
            return {property: Object.assign(state.property, {gallery: response.data})};
          });

          this.props.enqueueSnackbar('Removed the image', {variant: 'success'});

        }
      })      
      .catch(error => {
        console.log('There was an error!' + error);
        this.props.enqueueSnackbar('Error in removing the image', {variant: 'error'});
      });
  }

  validateProperty(){
    let property = this.state.property;
    /* eslint-disable no-useless-escape */
    const currencyRegex = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(\.[0-9]{1,2})?$/;
    const phoneNumberRegex = /^[\(]?\d{3}[\)]?[- ]?\d{3}[- ]?\d{4}$/;
    // const emailRegex = '^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$';
    const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const propertyTypeValidator = (value) => this.state.propertyTypes.findIndex(pt => pt.id === value) > -1;
    const validations = [
      {title: [{ruleName: 'required', message: 'Title is required'}]},
      // {url: [{ruleName: 'required', message: 'Url is required'}, 
      //   {ruleName: 'regex', regex: urlRegex, message: 'Url is not in valid format'}]},
      {address: [{ruleName: 'required', message: 'Address is required'}]},
      {propertyTypeId: [{ruleName: 'required', message: 'Property type is required', validate: propertyTypeValidator}]},
      // {propertyOptions: [{ruleName: 'required', message: 'Select atleast one option'}]},
      // {priceCategoryId: [{ruleName: 'required', message: 'Select price category'}]},
      {propertyOwnership: [{ruleName: 'required', message: 'Select property ownership'}]},
      // {category: [{ruleName: 'required', message: 'Select property category'}]},
      {description: [{ruleName: 'required', message: 'Description required'}]},
      {rentPerDay: [{ruleName: 'requiredIf', required: () =>this.state.property.isShortTermRent, message: 'Rent per day is required'},
        {ruleName: 'regex', validate: () => this.state.property.isShortTermRent, regex: currencyRegex, message: 'Rent per day not valid'}]},
      {rentPerMonth: [{ruleName: 'requiredIf', required: () => this.state.property.isLongTermRent, message: 'Rent per month is required'},
        {ruleName: 'regex', validate: () => this.state.property.isLongTermRent, regex: currencyRegex, message: 'Rent per month not valid'}]},
      {fullCost: [{ruleName: 'requiredIf', required: () => this.state.property.isForSale, message: 'Full cost is required'},
        {ruleName: 'regex', validate: () => this.state.property.isForSale, regex: currencyRegex, message: 'Full cost is not valid'}]},
      {monthlyPayment: [{ruleName: 'requiredIf', required: () => this.state.property.isForSale, message: 'Monthly payment is required'},
        {ruleName: 'regex', validate: () => this.state.property.isForSale, regex: currencyRegex, message: 'Monthly payment is not valid'}]},
      {ownerFirstName: [{ruleName: 'required', message: 'Owner first name required'}]},
      {ownerLastName: [{ruleName: 'required', message: 'Owner last name required'}]},
       {ownerPhone: [{ruleName: 'required', message: 'Owner phone required'},
         {ruleName: 'regex', regex: phoneNumberRegex, message: 'Incorrect phone format'}]},
      {ownerAddress: [{ruleName: 'required', message: 'Owner address required'}]},
      {ownerEmail: [{ruleName: 'required', message: 'Owner email required'},
        {ruleName: 'regex', regex: emailRegex, message: 'Incorrect email format'}]},
      
    ];

    let errors = validateForm(validations, property);

    // Set the errors to the state.
    this.setState({formErrors: errors});

    // Perform validation for short term, long term or sale selection.
    if(!property.isShortTermRent && !property.isLongTermRent && !property.isForSale){

      this.props.enqueueSnackbar('Please select at least one of short term rent, long term rent and sale', {variant: 'error'});
      return false;
    }
    
    return Object.keys(errors).length === 0;
  }

  onMainImageFileChange(e){
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    const property = this.state.property;

    console.log('selected file: ' + file.name);

    if(file){
      // Read the file and set the image to the img tag.
      var fr = new FileReader();
      fr.onload = () => {
         // set the file to the state and open the dialog.
        // this.setState({selectedFile: file, dialogOpen: true});
          this.setState({imageCropData: {propertyId: property.id,
          isMainImage: true,  selectedFileResult: fr.result, dialogOpen: true, fileName: file.name}});
      };

      fr.readAsDataURL(file);
    }

  }

  onGalleryImageFileChange(e){
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    const property = this.state.property;

    console.log('selected file: ' + file.name);

    if(file){
      // Read the file and set the image to the img tag.
      var fr = new FileReader();
      fr.onload = () => {
        // set the file to the state and open the dialog.
        this.setState({imageCropData: {propertyId: property.id,
          isMainImage: false,  selectedFileResult: fr.result, dialogOpen: true, fileName: file.name}});
      };

      fr.readAsDataURL(file);
    }


  }

  setImageUploadResult(resultImages, isMainImage){
    if(!isMainImage){
      // set the gallery of images.
      this.setState((state) => {
        return {property: Object.assign(state.property, {gallery: resultImages})};
      });
    }
    else if(resultImages && resultImages.length > 0){
      this.setState((state) => {
      return {property: Object.assign(state.property, {mainImageUrl: resultImages[0]})};
      });
    }
  }

  render(){
    let property = this.state.property;
    let errors = this.state.formErrors;
    let categories = this.state.categories;
    let priceCategories = this.state.priceCategories;
    let propertyOptions = this.state.propertyOptions;
    let militaryBases = this.state.militaryBases;
    let propertyTypes = this.state.propertyTypes;
    let propertyOwnerships = this.state.propertyOwnerships;
    let imageCropData = this.state.imageCropData;

    let selectedBases = this.state.property.militaryBases;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const menuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

 

    return (
      <div className="w-full">
        <div className="flex flex-wrap mx-2 mb-8">
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-grey-dark flex items-center justify-center">
              <table className="table-fixed w-full text-left text-gray-500 property-view-table">
                <tbody>
                      <tr><th className="w-1/4">Title<span className="mandatory-indicator">*</span></th>
                        <td className="w-3/4">
                        <TextField type="text" value={property.title} placeholder="title" error={!!errors.title}
                          onChange={(e)=> this.updateProperty({title: e.target.value})} 
                          helperText={errors.title?.message} fullWidth />
                        </td>
                      </tr>
                  {/* <tr><th>Url</th>
                    <td>
                    <TextField type="text" value={property.url} placeholder="url" error={!!errors.url}
                      onChange={(e)=> this.updateProperty({url: e.target.value})}
                      helperText={errors.url?.message} fullWidth />
                    </td>
                  </tr> */}
                  <tr><th>Type</th>
                    <td>
                      <Select native value={property.propertyTypeId} fullWidth
                        onChange={(e)=> this.updatePropertyType(+e.target.value)}
                        inputProps={{name: 'Type',id: 'property-type'}}
                        error={!!errors.propertyTypeId}
                      >
                        <option value="0">-- Select Type --</option>
                        {
                          propertyTypes && propertyTypes.map(pt => <option key={pt.id} value={pt.id}>{pt.name}</option>)
                        }
                      </Select>
                    
                    </td>
                  </tr>
                  <tr><th>Options</th>
                    <td>
                      {
                        property.propertyOptions && 
                        <Select className="w-full"
                          id="options-container"
                          multiple
                          value={property.propertyOptions}
                          onChange={(e) => {console.log(e.target.value); this.updateProperty({propertyOptions: e.target.value})}}
                          input={<Input />}
                          renderValue={(selected) => this.getSelectedOptions(selected)}
                          MenuProps={menuProps}
                          error={!!errors.propertyOptions}
                        >
                          {propertyOptions && propertyOptions.map((po) => (
                            <MenuItem key={po.id} value={po.id}>
                              <Checkbox checked={property.propertyOptions.indexOf(po.id) > -1} />
                              <ListItemText primary={po.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    </td>
                  </tr>
                  <tr><th>Price Category</th>
                    <td>
                      <Select native value={property.priceCategoryId} fullWidth aria-label="priceCategoryId"
                        onChange={(e)=> this.updateProperty({priceCategoryId: e.target.value})}
                        inputProps={{name: 'price category',id: 'property-price-category'}}
                        error={!!errors.priceCategoryId}>
                        <option value="null">-- Select Price Category --</option>
                       {
                         priceCategories && priceCategories.map(pc => <option key={pc.id} value={pc.id}>{pc.name}</option>)
                       }
                      </Select>

                      
                    </td>
                  </tr>
                  <tr><th>Property Ownership</th>
                  <td>
                    <Select native value={property.propertyOwnership} fullWidth aria-label="propertyOwnershipText"
                      onChange={(e)=> this.updateProperty({propertyOwnership: e.target.value})}
                      inputProps={{name: 'property ownership',id: 'property-ownership'}}
                      error={!!errors.propertyOwnership} >
                      <option value="null">--Select Ownership --</option>
                      {
                        propertyOwnerships && propertyOwnerships.map(po => <option key={po.id} value={po.id}>{po.name}</option>)
                      }
                    </Select>

                  </td>
                  </tr>
                  <tr><th>Category</th>
                  <td>
                    <Select native value={property.category} fullWidth aria-label="category"
                      onChange={(e)=> this.updateProperty({category: e.target.value})}
                      inputProps={{name: 'category',id: 'property-category'}}
                      error={!!errors.category} >
                      <option value="null">-- Select Category --</option>
                      {
                        categories && categories.map(cat => <option key={cat} value={cat}>{cat}</option>)
                      }
                    </Select>

                  </td>
                  </tr>
                  <tr><th>Short Term Rent</th>
                    <td>
                      <div className="flex flex-row w-full">
                        <div className="flex-shrink">
                          <Switch
                          checked={property.isShortTermRent === 1}
                          onChange={(e) => this.updateProperty({isShortTermRent: e.target.checked ? 1: 0})}
                          name="isShortTermRent"
                          color="primary"
                          inputProps={{ 'aria-label': 'isShortTermRent' }}
                          />
                        </div>
                        <div className="flex-grow ml-3">
                        <TextField className="w-full" disabled={!property.isShortTermRent}  id="standard-basic" label="Rent per Day" type="number" value={property.rentPerDay}
                        InputLabelProps={{shrink: true,}} onChange={(e) => this.updateProperty({rentPerDay: e.target.value})} 
                        error={!!errors.rentPerDay} helperText={errors.rentPerDay?.message}/>
                        </div>
                      </div>
                      
                      
                    </td>
                  </tr>
                  <tr><th>Long Term Rent</th>
                    <td>
                      <div className="flex flex-row w-full">
                        <div className="flex-shrink">
                          <Switch
                          checked={property.isLongTermRent === 1}
                          onChange={(e) => this.updateProperty({isLongTermRent: e.target.checked ? 1: 0})}
                          name="isLongTermRent"
                          color="primary"
                          inputProps={{ 'aria-label': 'isLongTermRent' }}
                          />
                        </div>
                        <div className="flex-grow ml-3">
                        <TextField className="w-full" disabled={!property.isLongTermRent}  id="standard-basic" label="Rent per Month" type="number" value={property.rentPerMonth}
                        InputLabelProps={{shrink: true,}} onChange={(e) => this.updateProperty({rentPerMonth: e.target.value})} 
                        error={!!errors.rentPerMonth} helperText={errors.rentPerMonth?.message} />
                        </div>
                      </div>

                    </td>
                  </tr>
                  <tr><th>For Sale</th>
                    <td>
                      <div className="flex flex-row w-full">
                        <div className="flex-shrink">
                          <Switch
                            checked={property.isForSale === 1}
                            onChange={(e) => this.updateProperty({isForSale: e.target.checked ? 1: 0})}
                            name="forSale"
                            color="primary"
                            inputProps={{ 'aria-label': 'forSale' }}
                          />
                        </div>
                        <div className="flex flex-row flex-grow ml-3 w-full">
                          <div className="w-1/2">
                            <TextField  disabled={!property.isForSale}  id="standard-basic" label="Full Cost" type="number" value={property.fullCost}
                            InputLabelProps={{shrink: true,}} onChange={(e) => this.updateProperty({fullCost: e.target.value})} 
                            error={!!errors.fullCost} helperText={errors.fullCost?.message}/>
                          </div>
                          <div className="w-1/2">
                            <TextField  disabled={!property.isForSale}  id="standard-basic" label="Monthly Payment" type="number" value={property.monthlyPayment}
                            InputLabelProps={{shrink: true,}} onChange={(e) => this.updateProperty({monthlyPayment: e.target.value})} 
                            error={!!errors.monthlyPayment} helperText={errors.monthlyPayment?.message} />
                          </div>
                        </div>

                      </div>

                    </td>
                  </tr>
                  
                  <tr><th>Description</th>
                    <td>
                      <div className="w-full flex flex-col">
                        <textarea className="text-input-box h-60" type="text" placeholder="description" aria-label="description"
                        value={property.description} onChange={(e)=> this.updateProperty({description: e.target.value})} 
                        />
                        <div className="text-sm text-red-500">
                          {errors.description && <span>{errors.description?.message}</span>}
                        </div>
                      </div>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-grey-dark">
                <div className="w-full mb-5">
                  <div className="w-full flex flex-row my-3 justify-between">
                    <strong className="mr-5 w-1/4 px-3">Address</strong>
                    <div className="w-3/4">
                      <TextField type="text" value={property.address} placeholder="address" aria-label="address"
                            InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({address: e.target.value})}
                            error={!!errors.address} helperText={errors.address?.message}
                            onBlur={() => this.fetchLatLngForProperty()} fullWidth
                            InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton color="primary"
                                  onClick={() => this.fetchLatLngForProperty(true)} title="Refresh address gecode">
                                  <RefreshIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}/>
                      
                    </div>
                  </div>
                  <div className="w-full p-4" style={{height: '400px'}} id="directions-map">

                  </div>
                </div>

                <div className="w-full">
                  <table className="table-auto w-full text-left text-gray-500 property-view-table">
                    <thead>
                      <tr>
                        <th>Military Base</th>
                        <th>Miles</th>
                        <th>Minutes</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        selectedBases && selectedBases.map(b => (<tr key={b.militaryBaseId}>
                          <td>
                            <Select native value={b.militaryBaseId} fullWidth aria-label="militaryBaseName"
                              onChange={(e)=> this.militaryBaseChanged(b, +e.target.value)}
                              inputProps={{name: 'militarybase',id: 'militarybase'}}                              >
                              <option disabled value="null">--Select Base --</option>
                              {
                                militaryBases && militaryBases.map(mb => <option key={mb.id} value={mb.id}>{mb.name}</option>)
                              }
                            </Select>

                          </td>
                          <td>
                            <TextField type="text" value={b.milesToMilitaryBase} placeholder="miles" aria-label="milesToMilitaryBase"
                              InputLabelProps={{shrink: true}} onChange={(e)=> this.updateMilitaryBase(b, {milesToMilitaryBase: e.target.value})}
                              fullWidth/>
                            
                          </td>
                          <td>
                            <TextField type="text" value={b.minutesToMilitaryBase} placeholder="minutes" aria-label="minutesToMilitaryBase"
                              InputLabelProps={{shrink: true}} onChange={(e)=> this.updateMilitaryBase(b, {minutesToMilitaryBase: e.target.value})}
                              fullWidth/>
                            
                          </td>
                          <td>
                            <IconButton aria-label="show-map" onClick={()=>this.computeMapDirections(b)}><LocationOnIcon /></IconButton>
                          </td>
                          <td>
                            <IconButton aria-label="remove-base" onClick={()=>this.removeBase(b)}><ClearIcon /></IconButton>
                          </td>
                        </tr>))
                      }
                    </tbody>
                  </table>
                  
                  <div className="w-full flex flex-row justify-end">
                    <Button disabled={!property.latitude || !property.longitude} variant="contained" color="secondary" startIcon={<AddIcon/>} title="Add military base" 
                    onClick={this.addMilitaryBase.bind(this)}>Add</Button>
                  </div>
                </div>

                <div className="w-full mt-3">
                  <table className="table-fixed w-full text-left text-gray-500 property-view-table">
                    <tbody>
                      <tr><th className="pl-3">Owner First Name</th>
                        <td>
                          <TextField type="text" value={property.ownerFirstName} placeholder="owner first name" aria-label="ownerFirstName"
                            InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({ownerFirstName: e.target.value})}
                            fullWidth error={!!errors.ownerFirstName} helperText={errors.ownerFirstName?.message} />
                        </td>
                      </tr>
                      <tr><th className="pl-3">Owner Last Name</th>
                        <td>
                          <TextField type="text" value={property.ownerLastName} placeholder="owner last name" aria-label="ownerLastName"
                            InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({ownerLastName: e.target.value})}
                            fullWidth error={!!errors.ownerLastName} helperText={errors.ownerLastName?.message} />
                        </td>
                      </tr>
                      <tr><th className="pl-3">Owner Phone</th>
                        <td>
                          <TextField type="text" value={property.ownerPhone} placeholder="eg. (970) 596-2158" aria-label="ownerPhone"
                            InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({ownerPhone: e.target.value})}
                            fullWidth error={!!errors.ownerPhone} helperText={errors.ownerPhone?.message} />
                        </td>
                      </tr>
                      <tr><th className="pl-3">Owner Email</th>
                        <td>
                          <TextField type="text" value={property.ownerEmail} placeholder="eg. abc.def@xyz.com" aria-label="ownerEmail"
                            InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({ownerEmail: e.target.value})}
                            fullWidth error={!!errors.ownerEmail} helperText={errors.ownerEmail?.message} />
                        </td>
                      </tr>
                      <tr><th className="pl-3">Owner Address</th>
                      <td>
                        <TextField type="text" value={property.ownerAddress} placeholder="owner address" aria-label="ownerAddress"
                          InputLabelProps={{shrink: true,}} onChange={(e)=> this.updateProperty({ownerAddress: e.target.value})}
                          fullWidth error={!!errors.ownerAddress} helperText={errors.ownerAddress?.message} />
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap mx-2 mb-8">

          <div className="w-full flex justify-center my-3 pb-4 border-b">
            <Button onClick={this.savePropertyDetails} variant="contained" color="primary" size="large" 
              startIcon={<SaveIcon />}>Save Property</Button>
          </div>

          <div className="w-full flex md:flex-col lg:flex-row">
            <div className="w-full lg:w-1/2 lg:px-3">
              <p><strong>Main Image</strong></p>
              <div className="image-holder">
                <div className="image-remove-holder">
                  <div className="w-16 h-16 property-remove-button">
                    <IconButton onClick={e => this.removeImage(e, property.mainImageUrl, true)} aria-label="delete" title="Remove Image"><DeleteIcon /></IconButton>
                  </div>
                </div>
                <img className="w-full" alt="" src={property.mainImageUrl}></img>
              </div>
                <div className="flex justify-center mt-5">      
                   <input id="main-image-file" type="file" ref={this.mainImageFileRef} accept=".jpg, .jpeg, .gif, .png"
                   style={{display: 'none'}} onChange={this.onMainImageFileChange.bind(this)}/>

                  <Button variant="contained" color="secondary" disabled={!!property.mainImageUrl || property.id <= 0} 
                  startIcon={<AddIcon/>} onClick={() => this.mainImageFileRef.current.click()}
                    title="add mainImage">
                      Add Main Image
                  </Button>  
                </div>    
            </div>
            <div className="w-full lg:w-1/2 lg:px-3">
              <p><strong>Gallery</strong></p>
              <div className="w-full flex flex-wrap flex-row">
                {
                  property.gallery && property.gallery.map(url => 
                    <div key={url} className="md:w-full lg:w-1/3 md:py-2 lg:p-2 ">
                      <div className="image-holder">
                        <div className="image-remove-holder">
                          <div className="w-16 h-16 property-remove-button">
                            <IconButton onClick={e => this.removeImage(e, url, false)} aria-label="delete" title="Remove Image"><DeleteIcon /></IconButton>
                          </div>
                        </div>
                        <img className="w-full" src={url} alt="gallery image"></img>
                      </div>
                    </div>)
                }
              </div>
                <div className="flex justify-center mt-2">
                   <input id="main-image-file" type="file" ref={this.galleryImageFileRef} accept=".jpg, .jpeg, .gif, .png"
                   style={{display: 'none'}} onChange={this.onGalleryImageFileChange.bind(this)}/>

                  <Button variant="contained" color="secondary" disabled={property.id <= 0} startIcon={<AddIcon/>} onClick={() => this.galleryImageFileRef.current.click()}
                    title="add image">
                      Add Image
                  </Button> 
                </div>
            </div>
          </div>
        </div>

        <ImageCropper 
          isOpen={imageCropData.dialogOpen}
          closeDialog={this.closeDialog}
          fileResult={imageCropData.selectedFileResult}
          propertyId={imageCropData.propertyId}
          setImageUploadResult={this.setImageUploadResult}
          isMainImage={imageCropData.isMainImage}
          fileName={imageCropData.fileName}
        ></ImageCropper>
      </div>
    );
  }
}

export default withSnackbar(PropertyEdit);
import React from 'react';
import { Dialog, Button, DialogActions, DialogContent,
  DialogTitle, Slide} from '@material-ui/core';
import { withSnackbar } from 'notistack';

import 'cropperjs/dist/cropper.css';

import Cropper from 'cropperjs';
import axios from 'axios';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ImageCropper extends React.Component {

  constructor(props){
    super(props);
    this.state = {};

    this.handleClose = this.handleClose.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.imageRef = React.createRef();
    this.imageLoaded = this.imageLoaded.bind(this);
    this.cropper = null;
    this.config = window.appConfig;
  }

  handleClose(){

    // remove the image.
    if(this.cropper){
      this.cropper.destroy();
      this.cropper = null;
    }
    
    this.props.closeDialog();
  }

  imageLoaded(e){

    if(this.imageRef.current && this.imageRef.current.complete){
      console.log('image loaded: ' + new Date());

      // Image load is complete. So show the cropper.
      const image = document.getElementById('cropperImage');

      if(this.cropper){
        this.cropper.destroy(); // destroy the existing cropper.
      }

      const aspectRation = image.naturalWidth / image.naturalHeight;

      this.cropper = new Cropper(image, {aspectRatio: aspectRation, viewMode: 2, guides: true, center: true, autoCropArea: 0.8});
    }
  }

  componentDidMount(){
    
  }
  
  uploadImage(e){
    console.log('upload clicked');

    // Get the cropped image.
    const base64ImageData = this.cropper.getCroppedCanvas().toDataURL();

    const url = this.config.baseUrl + 'property/save-image/';
    const input = {propertyId: this.props.propertyId, isMainImage: this.props.isMainImage,
      dataBase64: base64ImageData, name: this.props.fileName};

    axios.post(url, input)
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        // TODO: show the error message.
      }
      else {
        // show the succes message. Image uploaded successfully.
        this.props.setImageUploadResult(response.data, this.props.isMainImage);
        // set the gallery images to the server.
        this.props.closeDialog();
      }
      
    })      
    .catch(error => {
      console.log('There was an error!' + error);
      this.props.enqueueSnackbar('Error in saving the image', {variant: 'error'});
    });
  }
  
  render(){
    return (
      <div id="scout-dialog" className="w-1/2 lg:w-1/3">
        <Dialog
          open={this.props.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-center" id="alert-dialog-slide-title">{'Crop and upload'}</DialogTitle>
          <DialogContent>
            <img ref={this.imageRef} alt="" id="cropperImage" src={this.props.fileResult} className="invisible" 
              style={{display:'block', maxWidth:'100%'}} onLoad={this.imageLoaded}></img>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.uploadImage} color="primary" variant="contained">Upload</Button>
            <Button onClick={this.handleClose} color="secondary" variant="contained">Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withSnackbar(ImageCropper);
import React from 'react';
// import { useParams } from 'react-router';

export default class TextPage extends React.Component {

  constructor(props){
    super(props);
  }

  render(){
    // const { match: { params } } = this.props;

    return (
      // <div className="w-full flex flex-wrap -mx-2 mb-8">
      //    <div className="style=border:2px; border-b-2 border-fuchsia-600"><strong>Text Page</strong></div>
      // </div>
       <div className="w-full">
       <h1 className="m-4">Text Pages</h1>
 
       <div className="mt-3 justify-center">
         <table className="w-full table-auto shadow-lg bg-white">
           <thead>
           <tr className="text-center bg-gray-300">
             <th className="border p-4">#</th>
             <th className="border p-4">Title</th>
             <th className="border p-4">Slug</th>
           </tr>
           </thead>
           <tbody>
            
                <tr className="border text-center p-4">
                <td className=" p-4">1</td>
                <td><a href="#" className=" p-4">Privacy Policy</a></td>
                <td><a href="/privacy" className=" p-4 ">http://localhost.8080/page/privacy-policy</a></td>
                </tr>
                <tr className="border text-center p-4">
                <td className="border text-center p-4">2</td>
                <td><a href="#" className="  p-4">Terms of Use</a></td>
                <td><a href="/terms" className="  p-4">http://localhost.8080/page/terms-of-use</a></td>
                </tr>
                <tr className="border text-center p-4">
                <td className="border text-center p-4">3 <a href='/api/v1/print/example.pdf' target='_blank' rel='noopener noreferrer'>Testing</a></td>
                <td><a href="/faq" className="  p-4">FAQ</a></td>
                <td><a target="_blank" href={`${process.env.PUBLIC_URL}/faq.cshtml`} className="p-4" without rel="noopener noreferrer">http://localhost:8000/faq/</a></td>
                </tr>
                
           </tbody>
         </table>
       </div>
     </div>
    );
  }
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  

  const handleActionClick = (actionValue) => {
    props.actionSelected(actionValue);
  };

  const handleClose = () => {
    props.actionSelected(null);
  };

  let actions = props.actions || [{id: 1, name: 'Yes'}, {id: 0, name: 'No'}];

  return (
    <div>
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title || 'Confirm Dialog'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.bodyText || 'Are you sure you want to proceed?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map(a => <Button key={a.id} onClick={() => handleActionClick(a.id)}>{a.name}</Button>)}
        </DialogActions>
      </Dialog>
    </div>
  );
}
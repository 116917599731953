import React from 'react'
import axios from 'axios';
import MaterialTable from 'material-table';
import FilterTextField from '../formControls/filterTextField';
import CheckIcon from '@material-ui/icons/Check';
import { withSnackbar } from 'notistack';
import { Button} from '@material-ui/core';

class MillieUsersList extends React.Component{

  constructor(props){
    super(props);
    this.state = {userList: [], filters: {email: '', userType: -1, isMillieUser: 1, pageSize: 10, pageNo: 1}};
    this.config = window.appConfig;
    this.columns = [
      {title:'#', field:'id', filtering: false},
      {title:'Email', field:'email', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.email} />},

      {title:'Verified', field:'isConfirmedMillieUser',filtering: false, render: rowData => { 
          if(rowData.isConfirmedMillieUser === 0) {
            return <Button color="primary" variant="contained" onClick={() => this.confirmMillieUser(rowData)} startIcon={<CheckIcon/>} >Verify</Button> 
          }
          else {
            return <CheckIcon/>
          }
        }
      }
    ];

    this.tableRef = React.createRef();

  }
  componetDidMount(){
    //this.loadUsers();

  }

  confirmMillieUser(user){

    let url = this.config.baseUrl + 'user/verify-millie-user/' + user.id;

    axios.post(url, 
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar(`Confirmed millie user ${user.email}`, {variant: 'success'});
        this.tableRef.current && this.tableRef.current.onQueryChange();
      }
    })
    .catch(error => {
      this.props.enqueueSnackbar(error, {variant: 'error'});
    });
  }
 
  loadUsers(query){
    let localFilters = {};

    query.filters.forEach(filter => {
        localFilters[filter.column.field] = filter.value;
    });

    let filters = Object.assign(localFilters, {pageNo: query.page + 1,
      pageSize: query.pageSize,

    });

    filters = Object.assign(this.state.filters, filters);

    return new Promise((resolve, reject) => {

      let url = this.config.baseUrl + 'user/filter';

      axios.post(url, 
        filters,
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
          this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
        }
        else {
          console.log(response.data);
          return resolve({
            data: response.data.users,
            page: response.data.pageNo - 1,
            totalCount: response.data.totalRecords,
          });
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar(error, {variant: 'error'});

        return resolve({
          data: [],
          page: 0,
          totalCount: 0
        });
      });

      
    });
  }

  render(){
    let pageSize = this.state.filters.pageSize;

    return (
      <div className="w-full">

        <MaterialTable
        title="Millie User list"
        tableRef={this.tableRef}
        options={{
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [pageSize],
          toolbar: true,
          paging: true,
          filtering: true,
          search: false,
          sorting: false,
          paginationType: 'stepped'
        }}
        columns={this.columns}
        
        data={query => this.loadUsers(query)}
      />
      </div>
    );
  }
}
export default withSnackbar(MillieUsersList)



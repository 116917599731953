import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

const styles = {
  formControl: {
    margin: 0,
    fullWidth: true,
    backgroundColor: "#ffffff",
    width: "auto"
  }
};

class FilterTextField extends React.Component{
  constructor(props){
    super(props);
    this.state = {selectedValue: this.props.initialValue};

    this.handleChange = this.handleChange.bind(this);
    
  }

  handleChange(e) {
		const val = e.target.value;
		this.setState({selectedValue: val});
		this.props.onFilterChanged(this.props.columnDef.tableData.id, val);
	}


  render(){
    const { classes } = this.props;

    return (
    <FormControl className={classes.formControl}>
      <TextField
        value={this.state.selectedValue}
        onChange={this.handleChange}
      >
      </TextField>

    </FormControl>
    );
  }


}

export default withStyles(styles)(FilterTextField);
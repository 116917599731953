import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Collapse, colors } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import Accordion from "@material-ui/core/Accordion";


// const Accordion=withStyles({
//     root: {
//         width: '100%',
//          backgroundColor: 'rgba(31, 41, 55)',
//          color:'#00867d',
//     },  
//     ExpandMoreIcon : {
//         color:'#00867d',
//     },
    
//         });
       export default function App(){
           
        const [expanded, setExpanded] = React.useState(false);
 
        const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        };
// The Header creates links that can be used to navigate
// between routes.
// const Header = () => (
    return (
       
    <header style={{minWidth:'123px', height:'950px'}}>
        <ul className="side-linke" style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}} >
            <li className="typo_hover cursor-pointer" style={{paddingLeft:'16px'}}><HomeIcon/><NavLink to='/' > Home</NavLink></li>
            <li>
                <Accordion style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                        <AccordionSummary className="typo_hover" 
                            expandIcon={<ChevronLeftIcon  style={{color:'#00867d'}}/>}>
                            <Typography ><PersonIcon /> Users</Typography> 
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <li className="side-link"><NavLink to='/users' activeClassName="current-link">Users</NavLink></li>
                                <li className="side-link"><NavLink to='/millie-users' activeClassName="current-link">Millie Users</NavLink></li>
                            </Typography>
                        </AccordionDetails>
                </Accordion >
            </li>
            <li>
                <Accordion style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}}  expanded={expanded==="panel2"} onChange={handleChange("panel2")}>
                        <AccordionSummary className="typo_hover"
                            expandIcon={<ChevronLeftIcon  style={{color:'#00867d'}}/>}>
                            <Typography ><AccountBalanceIcon /> Properties</Typography> 
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <li className="side-link"><NavLink to='/properties' activeClassName="current-link">Properties</NavLink></li>
                                <li className="side-link"><NavLink to='/property-options' activeClassName="current-link">Options</NavLink></li>
                                <li className="side-link"><NavLink to='/millie-reviews' activeClassName="current-link">Millie Reviews</NavLink></li>
                            </Typography>
                        </AccordionDetails>
                </Accordion>
            </li>
            <li>
                <Accordion style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}}  expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                        <AccordionSummary className="typo_hover"
                            expandIcon={<ChevronLeftIcon style={{color:'#00867d'}} />}>
                            <Typography ><SearchIcon/> View Requests</Typography> 
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <li className="side-link"><NavLink to='/property-view-requests' activeClassName="current-link">View Requests</NavLink></li>
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}} expanded={expanded === "panel4"} onChange={handleChange("panel4")} >
                        <AccordionSummary className="typo_hover"
                            expandIcon={<ChevronLeftIcon style={{color:'#00867d'}}/>}>
                            <Typography ><DescriptionIcon/> Text Pages</Typography> 
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <li className="side-link"><NavLink to='/privacy-policy' activeClassName="current-link">Privacy Policy</NavLink></li>
                                <li className="side-link"><NavLink to='/terms-of-use' activeClassName="current-link">Terms Of Use</NavLink></li>
                                <li className="side-link"><NavLink to='/faq' activeClassName="current-link">FAQ</NavLink></li>
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion style={{minWidth:'123px' ,backgroundColor: 'rgba(31, 41, 55)',color:'#00867d'}} expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                        <AccordionSummary className="typo_hover"
                            expandIcon={<ChevronLeftIcon style={{color:'#00867d'}}/>}>
                            <Typography ><AttachMoneyIcon/> Rates</Typography> 
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <li className="side-link"><NavLink to='/bah-rates' activeClassName="current-link">BAH Rates</NavLink></li>
                                <li className="side-link"><NavLink to='/per-diem-rates' activeClassName="current-link">Per Diem Rates</NavLink></li>
                            </Typography>
                        </AccordionDetails>
                </Accordion>
            </li>

            {/* <li className="side-link"><NavLink to='/' >Home</NavLink></li>
                <ul className="list-none pl-4">
                    <li className="side-link"><NavLink to='/users' activeClassName="current-link">Users</NavLink></li>
                    <li className="side-link"><NavLink to='/millie-users' activeClassName="current-link">Millie Users</NavLink></li>
                </ul>
            </li>
            <li className="side-link"><NavLink to='/properties' activeClassName="current-link">Properties</NavLink></li>
            <li className="side-link"><NavLink to='/property-options' activeClassName="current-link">Options</NavLink></li>
            <li className="side-link"><NavLink to='/millie-reviews' activeClassName="current-link">Millie Reviews</NavLink></li>
            <li className="side-link"><NavLink to='/property-view-requests' activeClassName="current-link">View Requests</NavLink></li>
            <li className="side-link"><NavLink to='/text-page' activeClassName="current-link">Text pages</NavLink></li> 
            <li className="side-link"><NavLink to='/bah-rates' activeClassName="current-link">BAH Rates</NavLink></li>
            <li className="side-link"><NavLink to='/per-diem-rates' activeClassName="current-link">Per Diem Rates</NavLink></li>
            <li className="side-link"><NavLink to='/privacy-policy' activeClassName="current-link">Privacy Policy</NavLink></li>
            <li className="side-link"><NavLink to='/terms-of-use' activeClassName="current-link">Terms Of Use</NavLink></li>
            <li className="side-link"><NavLink to='/faq' activeClassName="current-link">FAQ</NavLink></li> */}
        </ul>
    </header>);
       };
// )

// export default Header;

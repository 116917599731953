import { Button, Typography } from '@material-ui/core';
import React from 'react';
import Papa from 'papaparse';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import JSONPretty from 'react-json-pretty';
import moment from 'moment';

// import { useParams } from 'react-router';

class BahRatesManager extends React.Component {

  constructor(props){
    super(props);

    this.state = {withDependentsSummary: {}, withoutDependentsSummary: {}};
    this.config = window.appConfig;

    this.withDependentsFileRef = React.createRef();
    this.withoutDependentsFileRef = React.createRef();
    this.names = [ 
      'E01', 'E02', 'E03', 'E04', 'E05', 'E06', 'E07', 'E08', 'E09',
      'W01', 'W02', 'W03', 'W04', 'W05', 
      'O01E', 'O02E', 'O03E',
      'O01',  'O02',  'O03',  'O04',  'O05',  'O06',  'O07'
      ];

    
  }

  componentDidMount(){
    this.getLoadSummary();
  }

  getLoadSummary(){
    let url = this.config.baseUrl + 'pay-grades/load-summary';
    
    axios.get(url,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        console.log(response.data);
        this.setState({withoutDependentsSummary: response.data[0], withDependentsSummary: response.data[1]});
      }     
    })
  }


  onWithDependentsFileChange(e){
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];

    
    this.parseData(file, 1); // 1 -- with dependents

    
    this.setState({file}); /// if you want to upload latter
  }

  onWithoutDependentsFileChange(e){
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];

    
    this.parseData(file, 0); // without dependents

    
    this.setState({file}); /// if you want to upload latter
  }

  parseData(file, withDependents){
    Papa.parse(file, {
      header: false,
      dynamicTyping: true, // so that we get the numbers as numbers and not strings.
      skipEmptyLines: true,
      complete: (results) => {
        console.log(results);

        // get the pay grades as an array of arrays.
        let allMaps = results.data.map(r => this.getRecords(r));

        // flatten the arrays.
        let payGrades = [].concat.apply([], allMaps);

        console.log(payGrades);

        // Save the pay grades.
        this.savePayGrades(payGrades, withDependents, results.errors);
      }
    });
  }

  getRecords(row){
    let mha = row[0];

    let records = [];
    for(let i = 0; i < this.names.length; i++){
      let bah = +row[i +1] * this.config.toCentsFactor;
      if(isNaN(bah)){
        console.log("invalid bah value at index: " + i);
        console.log(row);
      }
      records.push({mha: mha, name: this.names[i], bah: bah});
    }

    return records;
  }

  savePayGrades(payGrades, withDependents, errors){
    // Save the option.
    let url = this.config.baseUrl + 'pay-grades/save';
    let input = {
      bahRates: JSON.stringify(payGrades),
      withDependents: withDependents,
      errors: JSON.stringify(errors)
    }

    axios.post(url, 
      input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar(`Uploaded the pay grades  with${withDependents ? '': 'out'} dependents`, {variant:'success'});
        if(withDependents){
          this.setState({withDependentsSummary: response.data});        
        }
        else{
          this.setState({withoutDependentsSummary: response.data});        
        }
      }
    })
    .catch(error => {
      this.props.enqueueSnackbar(error, {variant: 'error'});
    });
  }

  

  
  render(){
    // const { match: { params } } = this.props;
  //   {title:'Property Ownership', field:'propertyOwnershipText', 
  //   filterComponent: (props) => <FilterDropdown {...props} initialValue={this.state.filters.propertyOwnership}
  //   options={[{id: 1, name: 'Accend'}, {id: 2, name: 'Realtor'}, {id: 3, name: 'H4N'}]} />
  // },
    let wds = this.state.withDependentsSummary;
    let wods = this.state.withoutDependentsSummary;

    let wdloadTime = wds.insertDateTimeUtc ? moment(wds.insertDateTimeUtc).format('MM/DD/YYYY, hh:mm:ss') : '-';
    let wodloadTime = wods.insertDateTimeUtc ? moment(wods.insertDateTimeUtc).format('MM/DD/YYYY, hh:mm:ss') : '-';
    return (
      <div className="flex flex-wrap py-4 mt-3">
        
        <div className="w-full lg:w-1/2 px-3">
          <Typography variant="h6" gutterBottom>Upload BAH Rates</Typography>
          <hr></hr>

          <div className="w-full flex flex-row justify-between mt-3">
            <input id="with-dependents-file" type="file" ref={this.withDependentsFileRef} accept=".csv,.txt"
              style={{display: 'none'}} onChange={this.onWithDependentsFileChange.bind(this)}/>
            <input id="without-dependents-file" type="file" ref={this.withoutDependentsFileRef} accept=".csv,.txt"
              style={{display: 'none'}} onChange={this.onWithoutDependentsFileChange.bind(this)}/>

            <Button color="primary" variant="contained" onClick={() => this.withDependentsFileRef.current.click()} >With dependents</Button>
            <Button color="primary" variant="contained" onClick={() => this.withoutDependentsFileRef.current.click()}>Without dependents</Button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-3">
          <div className="style=border:2px; mb-5"><Typography variant="h6" gutterBottom>Upload BAH Rates</Typography></div>
          <div className="flex w-full flex-row justify-between">
            <table className="table-fixed w-full  text-left text-gray-500">
              <thead>
                <tr><th colSpan="2">With dependents</th></tr>
              </thead>
              <tbody>
                <tr><td>Grades updated</td><td>{wds.payGradesUpdated}</td></tr>
                <tr><td>Total</td><td>{wds.payGradesTotal}</td></tr>
                <tr><td>Last upload Time</td><td>{wdloadTime}</td></tr>
                <tr><td>Errors</td>
                  <td>
                    <JSONPretty id="json-pretty" data={wds.errors}></JSONPretty>
                  </td>
                </tr>
              </tbody>  
            </table>

            <table className="table-fixed w-full  text-left text-gray-500">
              <thead>
                <tr><th colSpan="2">Without dependents</th></tr>
              </thead>
              <tbody>
                <tr><td>Grades updated</td><td>{wods.payGradesUpdated}</td></tr>
                <tr><td>Total</td><td>{wods.payGradesTotal}</td></tr>
                <tr><td>Last upload Time</td><td>{wodloadTime}</td></tr>
                <tr><td>Errors</td>
                  <td>
                    <JSONPretty id="json-pretty" data={wods.errors}></JSONPretty>
                  </td>
                </tr>
              </tbody>  
            </table>
          </div>
          
        </div>

        <div className="w-full lg:w-1/2 px-3 ">
          <Typography variant="h6" gutterBottom>Upload BAH Rates</Typography>
          <hr></hr>

          <table className="table-fixed w-full text-left text-gray-500 mt-3">
            <tbody>
              <tr>
                <th></th>
                <th><input type="text" placeholder="Enter text"></input></th>
                <th><Button color="primary" variant="contained">Update</Button></th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full lg:w-1/2 mt-4 px-3">
          <table className="table-fixed w-full text-left text-gray-500">
            <thead>
              <tr><th>Current BAS rates</th></tr>
            </thead>
            <tbody>
              <tr><td>Enlisted Rate: </td><td></td></tr>
              <tr><td>Officer Rate: </td><td></td></tr>
            </tbody>
          </table>
        </div>
    </div>
    );
  }
}

export default withSnackbar(BahRatesManager);
import React from 'react';
import { Select, MenuItem, Dialog, Button, DialogActions, DialogContent,
  DialogTitle, Slide, InputLabel} from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default class ScoutDialog extends React.Component {

  constructor(props){
    super(props);
    this.state = {selectedEmail: ''};

    this.handleClose = this.handleClose.bind(this);
    this.emailSelected = this.emailSelected.bind(this);
  }

  handleClose(){
    this.props.closeDialog();
  }

  emailSelected(){
    this.props.emailSelected(this.state.selectedEmail);
  }
  
  render(){
    return (
      <div id="scout-dialog" className="w-1/2 lg:w-1/3">
        <Dialog
          open={this.props.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle className="text-center" id="alert-dialog-slide-title">{'Send to Scout'}</DialogTitle>
          <DialogContent>
             <FormControl required className="w-full justify-items-strech">
              <InputLabel id="scout-email-label">Select Email</InputLabel>
              <Select
                labelId="scout-email-label"
                id="scout-email"
                value={this.state.selectedEmail}
                onChange={(e) => this.setState({selectedEmail: e.target.value})}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.emails.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button disabled={!this.state.selectedEmail} onClick={this.emailSelected} color="primary" variant="contained">Send</Button>
            <Button onClick={this.handleClose} color="secondary" variant="contained">Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
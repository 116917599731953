
import './index';

import React from "react";
import Header from './header';
import Main from './main';
import Footer from './footer';
import Login from './pages/login';
import axios from 'axios';



class App extends React.Component{

  constructor(props){
    super(props);

    let userData = {};
    var userDataString = localStorage.getItem("userData");
    if(userDataString){
      userData = JSON.parse(userDataString);
      this.state = {userData: userData};
      this.setAuthHeader();
    }
    else{
      this.state = {userData: {}};
    }

    this.setUserData = this.setUserData.bind(this);

    this.logoutUser = this.logoutUser.bind(this);

    console.log('process.env.PUBLIC_URL: ' + process.env.PUBLIC_URL);
  }

  setUserData(userData){
    this.setState({userData: userData}); 

    localStorage.setItem("userData", JSON.stringify(userData));

    this.setAuthHeader();
  }

  setAuthHeader(){
    if(this.state.userData && this.state.userData.apiToken){
      axios.defaults.headers.common['Authorization'] = this.state.userData.apiToken;

      // Add a request interceptor
      // axios.interceptors.request.use(function (config) {
      //   const token = this.state.userData.apiToken;
      //   config.headers.Authorization =  token;

      //   return config;
      // });

    }
  }

  logoutUser(){

    // clear the userData from the localstorage.
    localStorage.removeItem("userData");

    // remove the user data from the state as well.
    this.setState({userData: {}});
  }

  
  render(){
    let userData = this.state.userData;
    if(userData.apiToken && userData.apiToken.length > 10){
      return (<main className="flex flex-col h-screen">
        <div className="flex bg-gray-300 h-16 p-4">
          <div className="w-1/2 flex justify-start">
            <label className="text-gray-600 text-2xl">Home4Now</label>
          </div>
          <div className="w-1/2 flex justify-end">
            {
              userData && userData.apiToken && 
              <p>
                <span>{userData.email}</span>
                <a href="#" className="text-gray-700 pl-5 cursor-pointer" onClick={this.logoutUser}><i className="fas fa-sign-out-alt"></i> Log out</a>
              </p>
              
            }
          </div>
        </div>
        <div className="flex overflow-hidden">
          <div className="flex bg-gray-800 w-60 p-4 flex-0" style={{minWidth: '12rem'}}>
            <Header />
          </div>
          <div className="flex flex-col flex-1 max-w-full overflow-x-auto">
            
            <div className="flex paragraph px-4">
              <Main />
            </div>
          </div>
        </div>
        <div className="flex flex-col bg-gray-300 mt-4">
          <Footer />
        </div>
      </main>);
    }
    else {
      return <Login setUserData={this.setUserData} />
    }
  }
}


export default App;

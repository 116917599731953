import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/home';
import UsersList from './pages/usersList';
import MillieUsersList from './pages/millieUsersList';
import PropertiesList from './pages/propertiesList';
import PropertViewRequestList from './pages/propertyViewRequestList';
import PropertyView from './pages/propertyView';
import PropertyReview from './pages/propertyReview';
import TextPage from './pages/textPage';
import BahRatesManager from './pages/bahRatesManager';
import PerDiemRatesManager from './pages/perDiemRatesManager';
import PropertyEdit from './pages/propertyEdit';
import MillieReviews from './pages/millieReviews';
import PropertyOptionTypeMap from './pages/propertyOptionTypeMap';
import ViewPropertyReview from './pages/viewPropertyReview';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsOfUse from './pages/termsOfUse';
import FAQ from './pages/faq';
import UserRegistration from './pages/userRegistration';




const Main = () => (
  <main className="w-full">
    <Switch>
      <Route exact path='/' component={Home}/>
      <Route path='/users' component={UsersList}/>
      <Route path='/millie-users' component={MillieUsersList}/>
      <Route path='/properties' component={PropertiesList}/>
      <Route path='/property-view-requests' component={PropertViewRequestList}/>
      <Route path='/property-view/:propertyId' component={PropertyView}/>
      <Route path='/property-edit/:propertyId' component={PropertyEdit}/>
      <Route path='/property-create' component={PropertyEdit}/>
      {/* <Route path='/text-page/' component={TextPage}/> */}
      <Route path='/bah-rates' component={BahRatesManager}/>
      <Route path='/per-diem-rates' component={PerDiemRatesManager}/>
      <Route path='/create-millie-review/:propertyId' component={PropertyReview}/>
      <Route path='/millie-reviews' component={MillieReviews}/>
      <Route path='/view-millie-review/:propertyId' component={ViewPropertyReview}/>
      <Route path='/property-options' component={PropertyOptionTypeMap}/>
      <Route path='/privacy-policy' component={PrivacyPolicy}/>
      <Route path='/terms-of-use' component={TermsOfUse}/>
      <Route path='/faq' component={FAQ}/>
      <Route path='/user-create' component={UserRegistration}/>
    </Switch>
  </main>
)

export default Main; 

import axios from 'axios';
import React from 'react';
import { withSnackbar } from 'notistack';
import StarIcon from '@material-ui/icons/Star';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

class ViewPropertyReview extends React.Component {

  constructor(props){
    super(props);

    this.state = {propertyId: 0, property:{}};
    
    this.config = window.appConfig;
    this.verifyPropertyReview = this.verifyPropertyReview.bind(this);

  }

  componentDidMount(){
    const { match: { params } } = this.props;
    this.setState({propertyId: params.propertyId || 0});

    this.loadPropertyReview(params.propertyId);
  }

  loadPropertyReview(propertyId){
    let url = this.config.baseUrl + 'property/load-review/' + propertyId;
    
    axios.get(url,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        console.log(response.data);
        this.setState({property: response.data});
      }     
    })
  }

  verifyPropertyReview(){
    let url = this.config.baseUrl + 'property/verify-review/' + this.state.propertyId;
    
    axios.post(url,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar('Review Verified successfully.', {variant: 'success'});

        this.loadPropertyReview(this.state.propertyId);
      }     
    });
  }

  

  render(){

    let ratings = [1, 2, 3, 4, 5];
    let property = this.state.property;

    return (
      <div>
        <h2 className="mt-4 p-3">Property Review</h2>
        <div className="flex flex-row justify-center my-4">
          
          <div className="w-full lg:w-1/2 p-3" >
            <div className="">
              <label>Review</label>
              <textarea className="w-full border border-gray-200 focus:outline-none focus:ring focus:ring-cyan-600 h-32" readOnly={true} value={property.review} ></textarea>
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-3" >
            <label>Gallery</label>
          </div>
          
        </div>

        <div className="w-full p-3" >
          <label>Rating</label>
          <div className="w-auto h-auto flex flex-row justify-start">
            {
              ratings.map(r => r <= property.rating ? 
              <StarIcon key={r} fontSize="large" color="primary" /> : 
              <StarIcon key={r} fontSize="large" color="secondary" />)
            }
          </div>
        </div>

        <div>    
          <div className="w-full p-3 flex flex-row justify-start space-x-3" >
              <Button onClick={this.verifyPropertyReview} variant="contained" color="primary" size="large" disabled={this.property.reviewIsVerified}
                startIcon={<DoneIcon />} >Verify</Button>
              <Button href={this.config.clientBasePath + '/millie-reviews'} variant="contained" color="secondary" size="large">Back to reviews</Button>
          </div>
        </div>
        
      </div>
    );
  }
}

export default withSnackbar(ViewPropertyReview);




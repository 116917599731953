
const validateForm = (validations, obj) => {
  let errors = {};
  validations.forEach(val => {
    let field = Object.keys(val)[0];
    let rules = val[field];

    for(let i = 0; i < rules.length; i++){
      let rule = rules[i];
      try {
        if(rule.ruleName === 'required' && !isValid(obj[field], rule)){
          errors[field] = {message: rule.message};
          break;
        }
        else if(rule.ruleName === 'requiredIf' && rule.required() &&  !isValid(obj[field], rule)){
          errors[field] = {message: rule.message};
          break;
        }
        else if(rule.ruleName === 'regex' && (!rule.validate || rule.validate()) && !new RegExp(rule.regex).test(obj[field])){
          errors[field] = {message: rule.message};
          break;
        }
        else if(rule.ruleName === 'minLength' && obj[field].length < rule.minLength){
          errors[field] = {message: rule.message};
          break;
        }
      }
      catch(err) {
        console.log(err);
      }
    }
  });

  return errors;
}

const isValid = (value, rule) => {

  if(rule.validate){
    return rule.validate(value);
  }

  if(Array.isArray(value)){
    return value.length > 0;
  }

  return !!value;
}

export default validateForm;
import React from 'react'
import axios from 'axios';
import MaterialTable from 'material-table';
import FilterDropdown from '../formControls/filterDropdown';
import FilterTextField from '../formControls/filterTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../components/confirmDialog'
import { withSnackbar } from 'notistack';
import { Button, Typography, Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';



class UsersList extends React.Component{

  constructor(props){
    super(props);
    this.state = {userList: [], filters: {email: '', userType: -1, isMillieUser: -1, pageSize: 10, pageNo: 1}, 
      isDialogOpen: false, currentUser: {}};
    this.config = window.appConfig;
    this.userTypes = [{id: 1, name: 'Active Duty'}, {id: 2, name: 'Veteran'}, {id: 3, name: 'Military Spouse'}];
    this.columns = [{title:'#', field:'id', filtering: false},

      {title:'Email', field:'email', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.email} />},

      {title:'Type', field:'militaryStatusText', 
        filterComponent:(props) => <FilterDropdown {...props} initialValue={this.state.filters.userType}
        options={this.userTypes} />},

      {title:'Is Millie User', field:'isMillieUser', 
        filterComponent: (props) => <FilterDropdown {...props} 
        initialValue={this.state.filters.isMillieUser} options={[{id: 2, name: 'No'}, {id: 1, name: 'Yes'}]} />, 
        render: rowData => rowData.isMillieUser? 'Yes': 'No'},

      {title:'', field:'id',filtering: false, render: rowData => <button className="button-icon" type="button" onClick={() => this.removeUser(rowData)}><DeleteIcon /></button>},
      
    ];

    this.removeUser = this.removeUser.bind(this);
    this.actionSelected = this.actionSelected.bind(this);

    this.tableRef = React.createRef();
  }

  componetDidMount(){
    // this.loadUsers();
  }

  loadUsers(query){
    let localFilters = {};

    query.filters.forEach(filter => {
      if(filter.column.field === 'militaryStatusText'){
        localFilters['userType'] = filter.value;
      }
      else if(filter.column.field === 'isMillieUser'){
        localFilters[filter.column.field] = filter.value === 2? 0: filter.value;
      }
      else {
        localFilters[filter.column.field] = filter.value;
      }
    });

    let filters = Object.assign(localFilters, {pageNo: query.page + 1,
      pageSize: query.pageSize,

    });

    filters = Object.assign(this.state.filters, filters);

    return new Promise((resolve, reject) => {

      let url = this.config.baseUrl + 'user/filter';

      axios.post(url, 
        filters,
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
        }
        else {
          console.log(response.data);
          
          // update the users.
          // this.setState({properties: response.data.properties, propertiesCount: response.data.totalRecords})

          console.log('data for page: ' + response.data.pageNo);
          return resolve({
            data: response.data.users,
            page: response.data.pageNo - 1,
            totalCount: response.data.totalRecords,
          });
        }
      });
    });
  }

  removeUser(currentUser){
    this.setState({isDialogOpen: true, currentUser: currentUser});
  }

  actionSelected(value){
    // Close the dialog

    this.setState({isDialogOpen: false});

    let currentUser = this.state.currentUser;
    this.setState({currentUser: {}});

    if(value === 1){
      // TODO: Remove the user by calling the API

      let url = this.config.baseUrl + 'user/delete/' + currentUser.id;
      axios.post(url, 
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
          this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
        }
        else {
          this.props.enqueueSnackbar(`User with email ${currentUser.email} is removed`, {variant: 'success'});
          this.tableRef.current && this.tableRef.current.onQueryChange();
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar(error, {variant: 'error'});
      });
    }
  }

  render(){
    let pageSize = this.state.filters.pageSize;

    return (
      <div className="w-full">

        <div className="w-full flex flex-row justify-between my-5">
          <Typography variant="h5" gutterBottom>User List</Typography>
          <Button component={Link} variant="contained" color="primary" startIcon={<AddIcon/>}
            href={this.config.clientBasePath + '/user-create/'} title="user signup">
              Add User
          </Button>
        </div>

        <ConfirmDialog isOpen={this.state.isDialogOpen} actionSelected={this.actionSelected} 
          actions={[{id: 1, name: 'Yes',}, {id: 0, name: 'No'}]} title={'User Delete Confirmation'} 
          bodyText={`Are you sure you want to remove the user with email ${this.state.currentUser.email}?`}
        />

        <MaterialTable
        tableRef={this.tableRef}
        options={{
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [pageSize],
          toolbar: true,
          paging: true,
          filtering: true,
          search: false,
          sorting: false,
          paginationType: 'stepped',
          toolbar: false,
          showTitle: false
        }}
        columns={this.columns}
        
        data={query => this.loadUsers(query)}
      />
      </div>
    );
  }
}

export default withSnackbar(UsersList)
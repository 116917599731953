import React from 'react'
import axios from 'axios';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import { Button, Typography} from '@material-ui/core';
import OptionsDialog from '../components/optionsDialog';
import ErrorBoundary from '../components/errorBoundary';


class PropertyOptionTypeMap extends React.Component{

  constructor(props){
    super(props);

    this.state = {dialogOpen: false, optionId: 0, optionName: ''};

    this.config = window.appConfig;
    this.columns = [
      {title:'#', field:'propertyOptionId', filtering: false},
      {title:'Name', field:'name', filtering: false, render: rowData => 
        <Button color="primary" onClick={() => this.editOption(rowData)}>{rowData.name}</Button>},
      {title:'CONDO', field:'condo', filtering: false, type: 'boolean', 
        render: rowData => <Checkbox color="primary" checked={rowData.condo} onChange={(e) => 
          this.updateRecord(rowData, 1, e.target.checked, 'condo')} /> },

      {title:'HOME', field:'home', filtering: false, type: 'boolean', 
        render: rowData => <Checkbox color="primary" checked={rowData.home} onChange={(e) => 
          this.updateRecord(rowData, 2, e.target.checked, 'home')} /> },

      {title:'APARTMENT', field:'apartment', filtering: false, type: 'boolean', 
        render: rowData => <Checkbox color="primary" checked={rowData.apartment} onChange={(e) => 
          this.updateRecord(rowData, 3, e.target.checked, 'apartment')} /> },

      {title:'MOBILE HOME', field:'mobileHome', filtering: false, type: 'boolean', 
        render: rowData => <Checkbox color="primary" checked={rowData.mobileHome} onChange={(e) => 
          this.updateRecord(rowData, 4, e.target.checked, 'mobileHome')} /> },
    ];

    this.tableRef = React.createRef();
    this.saveOption = this.saveOption.bind(this);
    this.addOption = this.addOption.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

  }

  componetDidMount(){
    //this.loadUsers();

  }

  editOption(rowData){
    this.setState({dialogOpen: true, optionId: rowData.propertyOptionId, optionName: rowData.name});

    console.log(rowData);
  }

  addOption(){
    this.setState({dialogOpen: true, optionId: 0, optionName: ''});
  }

  saveOption(optionData){
    // close the dialog and clear the data.
    this.setState({dialogOpen: false, optionId: 0, optionName: ''});

    // Save the option.
    let url = this.config.baseUrl + 'property-option/save';
    let input = optionData;

    axios.post(url, 
      input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar(`Saved property option: ${optionData.name}`, {variant:'success'});
        
        // Reload the material table.
        // this.tableRef.current && this.tableRef.current.onQueryChange();
        // Do not refresh the table data immediately as it is leading to an issue.
        setTimeout(() => {
          // dismiss all open snackbars
          this.props.closeSnackbar();
          this.tableRef.current && this.tableRef.current.onQueryChange();
        }, 500)
      }
    })
    .catch(error => {
      this.props.enqueueSnackbar(error?.response?.data || 'Error in saving option', {variant: 'error'});
    });
  }

  updateRecord(rowData, propertyTypeId, checked, key){
    // TODO: Send the property option id, propertyTypeId and checked to server and udpate the record
    let url = this.config.baseUrl + 'property/save-option-type';
    let input = {propertyOptionId:rowData.propertyOptionId,
      propertyTypeId: propertyTypeId, isActive: checked};

    

    axios.post(url, 
      input,
      {headers: { 'content-type': 'application/json' }},
    )
    .then(response => {
      if(response.status === false){
        console.log('Got error from the server' + response.statusText);
        this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
      }
      else {
        this.props.enqueueSnackbar(`Saved changes for ${rowData.name} and ${key} `, {variant:'success'});
        
        //console.log(rowData);
        rowData[key] = !rowData[key];
        //console.log(rowData);

        // update the component;
        this.forceUpdate()
        // update the records
        // this.tableRef.current && this.tableRef.current.onQueryChange();
        // this.forceUpdate();
      }
    })
    .catch(error => {
      this.props.enqueueSnackbar(error?.response?.data || 'Error in saving option', {variant: 'error'});
    });
  }

  closeDialog(){
    this.setState({dialogOpen: false});
  }
 
  loadRecords(query){
    
    return new Promise((resolve, reject) => {

      let page = (query.page || 0 ) + 1;
      let url = this.config.baseUrl + 'property/option-types/' + page;

      axios.get(url, 
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
          this.props.enqueueSnackbar(response.statusText, {variant: 'error'});
        }
        else {
          console.log(response.data);
          return resolve({
            data: response.data.optionTypeMaps,
            page: response.data.pageNo - 1,
            totalCount: response.data.totalRecords,
          });
        }
      })
      .catch(error => {
        this.props.enqueueSnackbar(error, {variant: 'error'});

        return resolve({
          data: [],
          page: 0,
          totalCount: 0
        });
      });

      
    });
  }

  render(){
    let pageSize = this.config.defaultPageSize * 3;

    console.log('pageSize: ' + pageSize);

    return (
      <ErrorBoundary>
      <div className="w-full">

        <MaterialTable
        title="Options"
        tableRef={this.tableRef}
        options={{
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [pageSize],
          toolbar: true,
          paging: false,
          filtering: false,
          search: false,
          sorting: false,
          paginationType: 'stepped'
        }}
        columns={this.columns}
        
        data={query => this.loadRecords(query)}
        />
        <div className="mt-2">
          <Button color="primary" variant="contained"  
                onClick={this.addOption} startIcon={<AddIcon/>}>Add Option
          </Button> 
        </div>  

        {
        this.state.dialogOpen && 
          <OptionsDialog 
          isOpen={this.state.dialogOpen}
          closeDialog={this.closeDialog}
          saveOption={this.saveOption}
          id={this.state.optionId}
          name={this.state.optionName}>
          </OptionsDialog>
        }
      </div>
      </ErrorBoundary>
    );
  }
}
export default withSnackbar(PropertyOptionTypeMap)



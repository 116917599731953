import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  formControl: {
    margin: 0,
    fullWidth: true,
    backgroundColor: "#ffffff",
    display: "flex",
    wrap: "nowrap",
    width: "auto"
  }
};

class FilterDropdown extends React.Component{
  constructor(props){
    super(props);
    this.state = {selectedValue: this.props.initialValue};

    this.handleChange = this.handleChange.bind(this);
    
  }

  handleChange(e) {
		const val = e.target.value;
		this.setState({selectedValue: val});
		this.props.onFilterChanged(this.props.columnDef.tableData.id, val);
	}


  render(){
    const { classes } = this.props;

    return (
    <FormControl className={classes.formControl}>
      <Select
        value={this.state.selectedValue}
        onChange={this.handleChange}
      >
        
        <MenuItem value="-1">All</MenuItem>
        {this.props.options.map(op => <MenuItem key={op.id} value={op.id}>{op.name}</MenuItem>)}
      </Select>

    </FormControl>
    );
  }


}

export default withStyles(styles)(FilterDropdown);
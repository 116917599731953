import React from 'react';
import { Dialog, Button, DialogActions, DialogContent,
  DialogTitle, Slide} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default class OptionsDialog extends React.Component {

  constructor(props){
    super(props);
    this.state = {name: props.name};

    this.handleClose = this.handleClose.bind(this);
    this.saveName = this.saveName.bind(this);
  }

  handleClose(){
    this.props.closeDialog();
  }

  saveName(){
    this.props.saveOption({id: this.props.id, name: this.state.name});
  }
  
  render(){
    console.log('selected option name:');
    console.log(this.state.name);

    return (
      <div id="options-dialog" className="w-1/2 lg:w-1/3">
        <Dialog
          open={this.props.isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle className="text-center" id="alert-dialog-slide-title">{ this.state.id ? 'Edit Property Option' : 'Add Property Option'}</DialogTitle>
          <DialogContent>
            <FormControl required className="w-full justify-items-strech">
              <TextField id="option-name-text" label="Property Option"
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}/>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button disabled={!this.state.name} onClick={this.saveName} color="primary" variant="contained">Save</Button>
            <Button onClick={this.handleClose} color="secondary" variant="contained">Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
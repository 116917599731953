import React from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import FilterDropdown from '../formControls/filterDropdown';
import FilterTextField from '../formControls/filterTextField';



class PropertyViewRequestList extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {properties: [], propertiesCount: 0, militaryBases: [], filters:{
      title: '',
      address: '',
      propertyInterest: -1,
      purchaseType: -1,
      propertyOwnership: -1,
      name: '',
      email: '',
      phone: '',
      payGrade: '',
      listingAgentEmail: '',
      listingAgentPhoneNumber: '',
      pageSize: 15, // If this is to be configurable, add it to the config.js file --TODO:
      pageNo: 1
    }};

    this.booleanOptions = [{name:'No', value:0}, {name:'Yes', value:1}]

    this.mounted = false;
    this.propertyInterestTypes = [{id: 1, name: 'Ready to lease'},
      {id: 2, name: 'Need detailed info and virtual tour'},
      {id: 3, name: 'Just looking'},
      {id: 4, name: 'Schedule a tour'},
    ]
    this.purchaseTypes = [{id: 1, name: 'Short Term Rent'},
      {id: 2, name: 'Long Term Rent'},
      {id: 3, name: 'For Sale'},
    ]

    this.config = window.appConfig;

    this.columns = [{title:'#', field:'id', filtering: false},
      {title:'Main Image', field:'mainImageUrl', filtering: false, render: rowData => <img src={rowData.mainImageUrl} alt={'property'} style={{width: 50, borderRadius: '10%'}}/>},

      {title:'Title', field:'title', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.title} />,
      render: rowData => <a className="text-green-400 hover:text-green-500 delay-1" href={this.config.clientBasePath + '/property-view/' + rowData.propertyId}>{rowData.title}</a>},

      {title:'Address', field:'address', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.address} />},

      {title:'Url', field:'url', filtering: false},

      {title:'Property Interest', field:'propertyInterest', 
        filterComponent:(props) => <FilterDropdown {...props} initialValue={this.state.filters.propertyInterest}
        options={this.propertyInterestTypes} />},

      {title:'Purchase Type', field:'purchaseType', 
      filterComponent:(props) => <FilterDropdown {...props} initialValue={this.state.filters.purchaseType}
      options={this.purchaseTypes} />},

      {title:'Cost', field:'cost', filtering: false},

      {title:'Property Ownership', field:'propertyOwnershipText', 
        filterComponent: (props) => <FilterDropdown {...props} initialValue={this.state.filters.propertyOwnership}
        options={[{id: 1, name: 'Accend'}, {id: 2, name: 'Realtor'}, {id: 3, name: 'H4N'}]} />
      },

      {title:'Property Category', field:'propertyCategory', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.propertyCategory} />},

      {title:'Name', field:'name', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.name} />},

      {title:'Email', field:'email', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.email} />},
     
      {title:'Phone', field:'phone', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.phone} />},

      {title:'Pay Grade', field:'payGrade', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.payGrade} />},

      {title:'Listing Agent Email', field:'listingAgentEmail', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.listingAgentEmail} />},

      {title:'Listing Agent Phone Number', field:'listingAgentPhoneNumber', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.listingAgentPhoneNumber} />},

      {title:'Created', field:'createdTime', filterComponent: (props) => <FilterTextField {...props} initialValue={this.state.filters.createdTime} />},

      


      {title:'Is Verified', field:'isVerified', 
        filterComponent: (props) => <FilterDropdown {...props} 
        initialValue={this.state.filters.isVerified} options={[{id: 2, name: 'No'}, {id: 1, name: 'Yes'}]} />, 
        render: rowData => rowData.isVerified? 'Yes': 'No'},

      {title:'Review Is Verified', field:'reviewIsVerified',
        filterComponent: (props) => <FilterDropdown {...props} 
        initialValue={this.state.filters.isVerified} options={[{id: 2, name: 'No'}, {id: 1, name: 'Yes'}]} />,
        render: rowData => rowData.reviewIsVerified? 'Yes': 'No' },

      {title:'Rating', field:'rating', filtering: false},

      
    ];

    this.pageChanged = this.pageChanged.bind(this);
  }

  componentDidMount(){
    this.mounted = true;
  }

  loadViewRequests(query){


    let localFilters = {};

    console.log(query);

    return new Promise((resolve, reject) => {

      let url = this.config.baseUrl + 'property-view-requests/list';

      let localFilters = {};

    query.filters.forEach(filter => {
      if(filter.column.field === 'propertyOwnershipText'){
        localFilters['propertyOwnership'] = filter.value === null? -1: filter.value;
      }
      else {
        localFilters[filter.column.field] = filter.value;
      }
    });

    let filters = Object.assign(localFilters, {pageNo: query.page + 1,
      pageSize: query.pageSize,

    });

    filters = Object.assign(this.state.filters, filters);


      axios.post(url, 
        filters,
        {headers: { 'content-type': 'application/json' }},
      )
      .then(response => {
        if(response.status === false){
          console.log('Got error from the server' + response.statusText);
        }
        else {
          console.log(response.data);
          
          // update the properties.
          // this.setState({properties: response.data.properties, propertiesCount: response.data.totalRecords})

          console.log('data for page: ' + response.data.pageNo);
          return resolve({
            data: response.data.viewRequests,
            page: response.data.pageNo - 1,
            totalCount: response.data.totalRecords,
          });
        }
      });

      
    });
  }

  pageChanged(pageNo){
    console.log('page Changed to: ' + pageNo);

    if(this.mounted){
      this.setState(function(state, props){
        return {
          filters: Object.assign(state.filters, {pageNo: pageNo + 1})
        };
      });
    }
  }

  render(){

    let pageSize = this.state.filters.pageSize;

    return (
    <div className="w-full" style={{overflowX: 'auto'}}>

      <MaterialTable
        title="View Requests"
        onChangePage={page => this.pageChanged(page)}
        options={{
          initialPage: 0,
          pageSize: pageSize,
          pageSizeOptions: [pageSize],
          toolbar: true,
          paging: true,
          filtering: true,
          search: false,
          sorting: false,
          paginationType: 'stepped'
        }}
        columns={this.columns}
        data={query => this.loadViewRequests(query)}
      />
    </div>
    );
  }

}

export default PropertyViewRequestList;
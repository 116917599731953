
const Home = () => (
    <footer>
        <div className="mx-auto p-3 text-center">
            Home Page
        </div>
    </footer>
  )
  
  export default Home;
  